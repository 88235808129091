var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language_header_change align_items_c"},[_c('div',{staticClass:"language_header_sub align_items_c",class:{ language_header_change_active: _vm.localLanguage == 0 },on:{"click":function($event){return _vm.changeLanguage(0)}}},[_c('span',{staticClass:"language_header_sub_span align_items_c",class:{
				language_header_sub_span_active: _vm.localLanguage == 0,
			}},[_vm._v("简")])]),_c('div',{staticClass:"language_header_sub align_items_c",class:{ language_header_change_active: _vm.localLanguage == 1 },on:{"click":function($event){return _vm.changeLanguage(1)}}},[_c('span',{staticClass:"language_header_sub_span align_items_c",class:{
				language_header_sub_span_active: _vm.localLanguage == 1,
			}},[_vm._v("繁")])]),_c('div',{staticClass:"language_header_sub align_items_c",class:{ language_header_change_active: _vm.localLanguage == 2 },on:{"click":function($event){return _vm.changeLanguage(2)}}},[_c('span',{staticClass:"language_header_sub_span align_items_c",class:{
				language_header_sub_span_active: _vm.localLanguage == 2,
			}},[_vm._v("EN")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }