<template>
	<div>
		<div class="app_alert">
			<div class="header_app_logo" @click="download">
				<img src="https://zy.metaera.media/assets/images/appdownload.png" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	created() {},
	data() {
		return {
			token: 0,
			active: 0,
		};
	},
	methods: {
		download() {
			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			}
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || isiOS) {
				//判断是ios还是android
				window.location.href =
					"https://apps.apple.com/cn/app/meta-era/id6450677755"; // 跳AppStore下载地址
			} else if (/(Android)/i.test(navigator.userAgent)) {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
			} else {
				window.location.href = "https://zy.metaera.media/MetaEra100.apk"; //打开调整的地址
			}
			var that = this;
			setTimeout(function () {
				that.$router.push({
					path: "/download",
				});
			}, 1000);
		},
		gotoDownLoad() {
			this.$router.push({
				path: "/download",
			});
		},
	},
};
</script>

<style scoped>
.app_alert {
	align-items: center;
	text-align: center;
	align-content: center;
	width: 100%;
	height: 60px;
	background: rgba(0, 0, 0, 0);
	opacity: 1;
	z-index: 999;
	position: fixed;
	bottom: 20px;
	z-index: 999;
}
.header_app_logo img {
	width: auto;
	height: 30px;
}
</style>
