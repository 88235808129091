import { render, staticRenderFns } from "./noData.vue?vue&type=template&id=141e7264&scoped=true&"
import script from "./noData.vue?vue&type=script&lang=js&"
export * from "./noData.vue?vue&type=script&lang=js&"
import style0 from "./noData.vue?vue&type=style&index=0&id=141e7264&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141e7264",
  null
  
)

export default component.exports