import Cookies from "js-cookie"; //取到用户信息唯一标识
import router from "../router"; //获取到路由表来监听当页的路由
import request from "./request"; //封装的axios请求
import { getUserId } from "@/utils/auth";
import { pvuv } from "@/api/home";
import { v4 as uuidv4 } from "uuid";
let ipobj = {};
// 把当前时间换成yyyy-mm-dd hh:mm:ss格式
function currentDate(date) {
	let year = date.getFullYear(); //年 从 Date 对象以四位数字返回年份
	let month = date.getMonth() + 1; //月 从 Date 对象返回月份 (0 ~ 11) ,date.getMonth()比实际月份少 1 个月
	let day = date.getDate(); //日 从 Date 对象返回一个月中的某一天 (1 ~ 31)
	let hours = date.getHours(); //小时 返回 Date 对象的小时 (0 ~ 23)
	let minutes = date.getMinutes(); //分钟 返回 Date 对象的分钟 (0 ~ 59)
	let seconds = date.getSeconds(); //秒 返回 Date 对象的秒数 (0 ~ 59)
	//修改月份格式
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	//修改日期格式
	if (day >= 0 && day <= 9) {
		day = "0" + day;
	}
	//修改小时格式
	if (hours >= 0 && hours <= 9) {
		hours = "0" + hours;
	}
	//修改分钟格式
	if (minutes >= 0 && minutes <= 9) {
		minutes = "0" + minutes;
	}
	//修改秒格式
	if (seconds >= 0 && seconds <= 9) {
		seconds = "0" + seconds;
	}
	//格式(yyyy-mm-dd hh:mm:ss)
	let currentFormatDate =
		year +
		"-" +
		month +
		"-" +
		day +
		" " +
		hours +
		":" +
		minutes +
		":" +
		seconds;
	return currentFormatDate;
}

router.afterEach((to, from, next) => {
	// if (to.query != null) {
	// 	ipobj.wz_id = to.query.id ? to.query.id : "";
	// } else {
	// 	ipobj.wz_id = "";
	// }
	let urlName = to.path ? to.path : "";
	let preUrl = from.path ? from.path : "";
	if (urlName == "/gw_detail" || urlName == "/kuaixun_wz") {
	} else {
		getEvent("page", "", "", "");
	}
});
// 获取当前页面的标识  以路由每个页面的name为准
router.beforeEach((to, from, next) => {
	let urlName = to.path ? to.path : "";
	let preUrl = from.path ? from.path : "";
	window.sessionStorage.setItem("url", urlName.replace(/\s+/g, "")); //当前页面 pg
	window.sessionStorage.setItem("preUrl", preUrl.replace(/\s+/g, "")); //上一个页面 prepg
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	window.pageYOffset = 0;
	next();
});

// 获取当前触发的事件    点击触发事件才会上报
function getEvent(e, wz_id, wz_title, author_id) {
	let uuid = localStorage.getItem("uuid");
	if (!uuid) {
		uuid = uuidv4();
		localStorage.setItem("uuid", uuid);
	}
	ipobj.wz_id = wz_id;
	ipobj.wz_title = wz_title;
	ipobj.time = currentDate(new Date()); //获取当前时间  time
	ipobj.event = e; //获取当前触发的事件 event
	ipobj.usid = getUserId(); //用户的唯一标识 usid
	ipobj.uuid = uuid;
	ipobj.author_id = author_id;
	ipobj.platform = "web";
	setTimeout(() => {
		ipobj.page = window.sessionStorage.getItem("url")
			? window.sessionStorage.getItem("url")
			: "";
		ipobj.prepg = window.sessionStorage.getItem("preUrl")
			? window.sessionStorage.getItem("preUrl")
			: "";
		pvuv(ipobj)
			.then((res) => {})
			.catch((error) => {});
	}, 200);
}
export default {
	ipobj, //要传给headers的值{}
	getEvent, //要抛出挂载到Vue全局在每个页面触发事件时调用，参数2个，（事件类型，布尔值——点击触发事件的普通接口传true）
};
