var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cropper-content"},[_c('div',{staticClass:"cropper"},[_c('img',{ref:"cropImg",attrs:{"id":"cropImg","src":_vm.imageUrl,"alt":""}})]),_c('div',{staticClass:"show-preview",style:({
				overflow: 'hidden',
				margin: '0 25px',
				display: 'flex',
				'align-items': 'center',
			})},[_c('div',{staticClass:"preview before"})])]),_c('div',{staticClass:"footer-btn"},[_c('div',{staticClass:"scope-btn"},[_c('label',{staticClass:"btn",staticStyle:{"margin-right":"10px"},style:({
					display: 'flex',
					'align-items': 'center',
				}),attrs:{"for":"uploads"}},[_vm._v("选择文件")]),_c('input',{staticStyle:{"position":"absolute","clip":"rect(0 0 0 0)"},attrs:{"type":"file","id":"uploads","accept":"image/png, image/jpeg, image/gif, image/jpg"},on:{"change":function($event){return _vm.selectImg($event)}}}),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":"","icon":"el-icon-zoom-in"},on:{"click":function($event){return _vm.cropperzoom(0.05)}}},[_vm._v("放大")]),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":"","icon":"el-icon-zoom-out"},on:{"click":function($event){return _vm.cropperzoom(-0.05)}}},[_vm._v("缩小")]),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.cropperRotate(-90)}}},[_vm._v("左旋转")]),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.cropperRotate(90)}}},[_vm._v("右旋转")]),(!_vm.showAvator && _vm.showType == 0)?_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.bannerScale()}}},[_vm._v(" 横幅比例 ")]):_vm._e(),(!_vm.showAvator && _vm.showType == 0)?_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.coverScale()}}},[_vm._v(" 封面比例 ")]):_vm._e(),(!_vm.showAvator && _vm.showType == 0)?_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.customScale()}}},[_vm._v(" 自由比例 ")]):_vm._e()],1),_c('div',{staticClass:"upload-btn"},[_c('el-button',{attrs:{"type":"primary","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.sureSava()}}},[_vm._v("确认修改")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }