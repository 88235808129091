<template> </template>

<script>
export default {
	name: "",
	data() {
		return {
			winHeight: window.innerHeight,
			totalHeight: 0,
		};
	},
	updated() {
		// console.log('这里执行了吗')
		// this.getHeight();
	},
	methods: {
		scroll() {
			const totalHeight =
				document.body.scrollHeight || document.documentElement.scrollHeight;
			const scrolled =
				document.body.scrollTop || document.documentElement.scrollTop;
			if (totalHeight <= this.winHeight + scrolled + 300) {
				this.$emit("reachBottom");
			}
		},
		getHeight() {
			this.totalHeight =
				document.body.scrollHeight || document.documentElement.scrollHeight;
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener("scroll", this.scroll);
		});
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.scroll);
	},
};
</script>

<style scoped>
.pagination-container {
	background: #fff;
	padding: 32px 16px;
}
.pagination-container.hidden {
	display: none;
}
</style>
