import language_e from "./language-e";

function s2e(str) {
	let tmp = language_e[str.trim()];
	if (tmp) {
		return tmp;
	} else {
		return str;
	}
}
function findKeyByValue(value) {
	return Object.keys(language_e).find((key) => language_e[key] === value);
}
function e2s(str) {
	let tmp = findKeyByValue(str.trim());
	if (tmp) {
		return tmp;
	} else {
		return str;
	}
}
export { s2e, e2s };
