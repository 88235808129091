<template>
	<div class="flex direction_column align_items_c">
		<div class="loginTab flex">
			<div class="active">用手机号重置密码</div>
		</div>
		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone">
					<span
						class="js-login__areaCode pointer"
						@click="showtimezone = !showtimezone"
					>
						+{{ loginForm.phone_code }}<i class="js-login__areaIcon"></i
					></span>
					<login__areaCode
						v-if="showtimezone"
						@phone_code="getphone_code"
					></login__areaCode>

					<input
						type="text"
						placeholder="请输入手机号码"
						v-model="loginForm.mobile"
					/>
				</p>
				<p class="js-login_title"> 手机验证码 </p>
				<p class="js-login__verification">
					<input
						placeholder="请输入6位短信验证码"
						type="text"
						v-model="loginForm.code"
					/>

					<input
						value="获取验证码"
						type="button"
						class="js-login__captcha pointer"
						@click="tosendSms"
						v-show="show"
					/>
					<span class="js-login__captcha" v-show="!show">{{ count }} s</span>
				</p>
				<p class="js-login_title"> 请输入新密码 </p>
				<p class="js-login__psd">
					<input
						type="password"
						v-model="loginForm.new_password1"
						name="password"
						data-vv-as="密码"
						placeholder="请输入8-24位密码"
					/>
				</p>
				<p class="js-login_title"> 确认密码 </p>
				<p class="js-login__psd">
					<input
						type="password"
						v-model="loginForm.new_password2"
						placeholder="请输入8-24位密码"
					/>
				</p>

				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					@click="resetPass"
					:disabled="
						loginForm.mobile == '' ||
						loginForm.code == '' ||
						loginForm.new_password1 == '' ||
						loginForm.new_password2 == ''
					"
				>
					确 认
				</button>
				<div class="js-login_reigister">
					拥有账号？<i @click="$parent.$parent.showStatus = 1">去登录</i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { sendSms, forgotPassword } from "@/api/home";
import login__areaCode from "./login__areaCode.vue";
export default {
	name: "",
	components: { login__areaCode },
	data() {
		return {
			loginForm: {
				mobile: "",
				code: "",
				new_password1: "",
				new_password2: "",
				phone_code: "86",
			},
			passType: "password",
			showtimezone: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
		};
	},
	methods: {
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		tosendSms() {
			sendSms({
				phone: this.loginForm.mobile,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		resetPass() {
			forgotPassword(this.loginForm)
				.then((res) => {
					if (res.code == 200) {
						this.$parent.$parent.showStatus = 1;
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}
.loginBtn {
	width: 335px;
	height: 50px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	margin-left: 10px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

@import url("../../assets/log.css");
</style>
