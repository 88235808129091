<template>
	<div class="noData">
		<div class="js-loading"
			><div class="js-noData"
				><img alt="暂无活动" src="https://staticn.jinse.cn/w/img/7007794.svg" />
				<span>暂无数据</span></div
			></div
		>
	</div>
</template>

<script>
export default {
	name: "",
};
</script>

<style scoped>
.js-loading {
	margin: 34px auto;
}

.js-loading .js-loadMoreBtn {
	width: 100%;
	height: 48px;
	border: 1px solid #f5f5f5;
	border-radius: 2px;
	background: #f5f5f5;
	text-align: center;
	cursor: pointer;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-noData {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-noData {
	flex-direction: column;
	min-height: 400px;
}

.js-loading .js-noData img {
	margin: 20px 0;
}

.js-loading .js-noData span {
	font-size: 15px;
	color: #999;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
</style>
