<template>
	<div>
		<div class="js-fixed-tools">
			<ul>
				<li @click="$router.push({ path: '/aboutus' })">
					<div class="left_box">
						<div class="left_box_con">
							<div class="left_box_con_tip">寻求报道</div>
						</div>
					</div>
				</li>
				<li>
					<div class="left_box_big">
						<div class="left_box_con">
							<div class="left_box_con_tip">
								<div class="left_box_con_tip_left">
									<img
										src="https://zy.metaera.media/assets/images/aboutus/download.png"
										alt=""
								/></div>
								<div class="left_box_con_tip_right">
									<div class="left_box_con_tip_right_top">MetaEra APP</div>
									<div class="left_box_con_tip_right_bottom">
										iOS &amp; Android
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
				<li @click="$router.push({ path: '/home_shequn' })" class="pointer">
					<div class="left_box">
						<div class="left_box_con">
							<div class="left_box_con_tip"
								>加入社群<br />
								Telegram</div
							>
						</div>
					</div>
				</li>
				<li @click="$router.push({ path: '/feed_back' })" class="pointer">
					<div class="left_box">
						<div class="left_box_con">
							<div class="left_box_con_tip">意见反馈</div>
						</div>
					</div>
				</li>
				<li @click="toTop" class="pointer">
					<div class="left_box">
						<div class="left_box_con">
							<div class="left_box_con_tip">返回顶部</div>
						</div>
					</div>
				</li>
				<li @click="toBot">
					<div class="left_box">
						<div class="left_box_con">
							<div class="left_box_con_tip">返回底部</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {
			tupian: "",
		};
	},
	mounted() {},
	methods: {
		toTop() {
			document.documentElement.scrollTop = 0;
		},
		toBot() {
			this.$nextTick(() => {
				let clientHeight = document.querySelector(".Main").scrollHeight;

				document.documentElement.scrollTop = clientHeight; //也可以用上文方法二写
			});
		},
	},
};
</script>

<style scoped>
.container {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	padding-top: 10%;
	text-align: center;
}

h1 {
	font-size: 20px;
}

.js-noPage,
.js-noPage img {
	width: 100%;
}

@font-face {
	font-family: DINOT-Regular;
	src: url(https://staticn.jinse.cn/w/fonts/3117fb8.ttf);
}

@font-face {
	font-family: DINOT-Medium;
	src: url(https://staticn.jinse.cn/w/fonts/3897a40.ttf);
}

body,
html {
	font-size: 12px;
	color: #333;
	background: #fff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
}

body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
span,
td,
textarea,
th,
ul {
	margin: 0;
	padding: 0;
}

input {
	outline: none;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: #272525;
	transition: all 0.2s;
}

i {
	font-style: normal;
}

.js-clear:after {
	clear: both;
	display: block;
	content: "";
	height: 0;
	line-height: 0;
	visibility: hidden;
}

.js-container--home,
.js-container--market {
	display: flex;
}

.js-container {
	/* width: 1170px; */
	margin: 0 auto;
}

[class*="js-icon-"] {
	display: inline-block;
	width: 16px;
	height: 16px;
}

.js-link {
	font-size: 16px;
	font-weight: 400;
	color: #2d2d35;
	line-height: 22px;
}

.js-link--primary {
	color: #0056ff;
}

.js-link--default {
	color: #909099;
}

.js-link--cursor {
	cursor: pointer;
}

.js-dialog--wrapper {
	right: 0;
	bottom: 0;
	overflow: auto;
	margin: 0;
}

.js-dialog--wrapper,
.js-mask {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
}

.js-mask {
	width: 100%;
	height: 100%;
	opacity: 0.5;
	background: #000;
}

.js-interval {
	width: 1px;
	height: 18px;
	background-color: #eaeaef;
}

.el1 {
	overflow: hidden;
	display: -webkit-box;
	display: -moz-box;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	box-orient: vertical;
	-webkit-line-clamp: 1;
}

.js-preLoading {
	width: 1170px;
	margin: 0 auto;
}

.js-preLoading dl {
	display: inline-block;
	width: 188px;
	height: 32px;
	padding-bottom: 24px;
}

.js-preLoading dt {
	display: block;
	float: left;
	width: 32px;
	height: 32px;
	background: rgba(0, 0, 0, 0.06);
}

.js-preLoading dd {
	float: right;
	display: block;
	width: calc(100% - 42px);
	padding-top: 2px;
}

.js-preLoading ol {
	display: block;
	width: 100%;
	height: 10px;
}

.js-preLoading ol,
.js-preLoading ol:first-child {
	background: rgba(0, 0, 0, 0.06);
}

.js-preLoading ol + ol {
	margin-top: 8px;
}

.js-main {
	width: 1170px;
	margin: 0 auto;
}

.js-main__l {
	float: left;
	width: 750px;
}

.js-main__r {
	float: right;
	width: 370px;
}

.js-right__module {
	position: relative;
	height: auto;
	margin-bottom: 44px;
}

.js-right__head {
	height: auto;
	min-height: 45px;
}

.js-button {
	display: inline-block;
	width: 170px;
	height: 38px;
	padding: 0;
	line-height: 38px;
	border: 1px solid #f39700;
	border-radius: 2px;
	text-align: center;
	background: #fff;
	font-size: 16px;
	color: #f39700;
	outline: none;
	cursor: pointer;
}

.js-button:hover {
	background: linear-gradient(135deg, #ffc462, #ffa84e);
	color: #fff;
}

.js-button--primary {
	background: linear-gradient(135deg, #ffc462, #f37c00);
	color: #fff;
	border: none;
}

.js-button--primary:hover {
	background: linear-gradient(135deg, #ffc462, #ffa84e);
}

.js-single__foot-generalize {
	display: inline-block;
	float: right;
	width: 44px;
	height: 22px;
	text-align: center;
	border: 1px solid #bcbcbc;
	margin-left: 10px;
	margin-top: -2px;
}

.js-single {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 40px;
}

.js-single__left {
	flex: none;
	width: 270px;
	height: 167px;
	display: block;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	border: 1px solid #eee;
	margin-right: 30px;
}

.js-single__left img {
	width: 100%;
	max-width: 100%;
}

.js-single__left:hover img {
	transform: scale(1.03);
}

.js-single__r,
.js-single__right {
	flex: 1;
	position: relative;
}

.js-title {
	color: #333;
	text-decoration: none;
	line-height: 28px;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 6px;
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
}

.js-title:hover {
	color: #397bff;
}

.js-title .js-tag {
	border-radius: 2px;
	margin-right: 4px;
	font-size: 14px;
	color: #fff;
	width: 46px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	padding: 0;
	font-weight: 500;
	transform: translateY(-3px);
	display: inline-block;
}

.js-title .js-tag-sole {
	background: #f29700;
}

.js-title .js-tag-depth {
	background: #397bff;
}

.js-title .js-tag-vote {
	background: #fff;
	color: #ffb100;
	border: 1px solid #ffb100;
	border-radius: 2px;
	width: 28px;
	height: 14px;
	font-size: 14px;
	font-family: PingFangSC-Regular, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	line-height: 14px;
	padding: 4px 7px;
}

.js-single__des {
	height: 66px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	line-height: 22px;
	color: #666;
	font-size: 14px;
	word-break: break-all;
}

.js-single__foot {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 10px;
	line-height: 22px;
	color: #999;
	font-weight: 400;
	font-size: 14px;
}

.js-single__footItem {
	float: right;
	display: inline-flex;
	align-items: center;
	margin-left: 15px;
	padding-top: 3px;
}

.js-single__footItem .icon {
	width: 15px;
	height: 15px;
}

.js-single__footItem .text {
	margin-left: 8px;
	line-height: 20px;
}

.js-single__foot-author {
	color: #999;
}

.js-single__foot-author:hover {
	color: #397bff;
}

.js-single__foot--time {
	color: #999;
}

.js-tag-top {
	font-size: 14px;
	color: #e0051e;
	margin-right: 8px;
	border: 1px solid #e0051e;
	border-radius: 2px;
	width: 44px;
	height: 22px;
	line-height: 22px;
	text-align: center;
	display: inline-block;
}

.fr {
	float: right;
}

.js-amount {
	margin: 5px 7px 0 0;
}

.js-photos {
	margin-bottom: 40px;
}

.js-photos h3 {
	font-size: 20px;
	color: #333;
	margin-bottom: 10px;
}

.js-photos__list {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 16px;
}

.js-photos__list a img {
	width: 248px;
	height: 154px;
	border-radius: 4px;
}

.js-photos__list .js-photos__list-num {
	transform: scale(1);
	border-radius: 10px;
	padding: 2px 8px 2px 21px;
	color: #fff;
	right: 10px;
	bottom: 10px;
	position: absolute;
	font-size: 12px;
	background-color: rgba(0, 0, 0, 0.6);
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAALGPC/xhBQAAAVBJREFUOBGtk7FKA0EQhnOSmEoxYCmIgkokBFFLC02Rzk4Qn8AgPkk6sTBvkEBEEAVJLLUQ7PIGqRRBzBtcvgk7srsc3F6Sge9m59+ZP8OFy+XmHXEcP8G08cXgprOTcSo4YmDB7AOc2+0LdjGPc5AhW1zAH/Rg0fvhM7QrKIoeZEhfA1agDlXw4xLhVMRQwxa9I+jDAOy4pxBtWcS8PNIiiqI2PUJqhG6YaqQNjiEvdhveYEsbsuZ/Q0xqDH/AEbxSr2U1k341lH+pByURiXXoY7o6qcyDegfubM0/q+EtF/4fVEZ7wWBJhshFUgcanGWBxFDDxEvEQ3g0Zk3Oe6bxBu3AnJ3kb+VcmuKE/A5iriHbdmGogua0DbXPNlNtg8OxFprF8FmLKfI3M5/2XJ6vYPINish7uSZV0OTbDQ7mknu52IdfyBo/DOwmu86ojgHShOpAtAkZLAAAAABJRU5ErkJggg==);
	background-size: 9px;
	background-position: 8px;
	background-repeat: no-repeat;
}

.js-base__foot {
	line-height: 22px;
	color: #999;
	font-size: 14px;
}

.js-base__foot .js-author {
	line-height: 24px;
	color: #999;
}

.js-base__foot .js-author img {
	vertical-align: bottom;
	width: 24px;
	height: 24px;
	border-radius: 100%;
	margin-right: 4px;
}

.js-base__footItem {
	display: inline-flex;
	align-items: center;
}

.js-base__footItem .icon {
	width: r(15px);
	height: r(15px);
}

.js-base__footItem .num {
	margin-left: r(8px);
	font-size: r(14px);
	font-family: PingFangSC-Semibold, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999;
	line-height: r(20px);
}

.js-big-photo {
	margin-bottom: 40px;
}

.js-big-photo h3 {
	font-size: 20px;
	color: #333;
	margin-bottom: 10px;
}

.js-big-photo .js-big-photo__content {
	margin-bottom: 16px;
}

.js-big-photo .js-big-photo__content img {
	width: 100%;
	height: 200px;
	border-radius: 4px;
}

.js-single--spread {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.js-single--spread img {
	width: 100%;
	max-width: 100%;
	height: 167px;
}

.live__marketBox {
	width: 104.5%;
	overflow: hidden;
	margin-top: 4px;
	margin-bottom: 14px;
}

.live__marketBox .card-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	overflow: visible;
}

.live__marketBox .card-slide {
	width: 128px !important;
	padding: 15px 10px;
	border: 1px solid #f5f5f5;
	background: #fff;
	margin-top: 3px;
}

.live__marketBox .coin-name {
	font-size: 16px;
	line-height: 16px;
}

.live__marketBox .coin-name,
.live__marketBox .coin-price {
	font-family: DINOT-Regular, DINOT;
	font-weight: 400;
	color: #000;
}

.live__marketBox .coin-price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
	font-size: 18px;
	line-height: 18px;
}

.live__marketBox .coin-price .icon {
	width: 10px;
	height: 12px;
	transform: rotate(180deg);
}

.live__marketBox .coin-list {
	margin-top: 15px;
}

.live__marketBox .list-item {
	margin-top: 13px;
}

.live__marketBox .item-name {
	font-size: 12px;
	font-family: PingFangSC-Regular, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
	line-height: 12px;
}

.live__marketBox .item-value {
	margin-top: 5px;
	font-size: 14px;
	font-family: DINOT-Regular, DINOT;
	font-weight: 400;
	color: #000;
}

.live__marketBox .item-value.up {
	color: #00b083;
}

.live__marketBox .item-value.down {
	color: #fd0863;
}

.js-message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 10010;
	top: 100px;
	left: calc(50% - 100px);
	margin-left: -130px;
	padding: 20px;
	text-align: center;
	color: #fff;
	font-size: 30px;
	transition: top 0.3s;
}

.message-enter-active,
.message-leave-active {
	transition: all 0.3s linear 0s;
}

.message-enter,
.message-leave-to {
	transform: translateY(-50px);
	opacity: 0;
}

.messagebox,
.timezone-list {
	position: absolute;
}

.timezone-list {
	max-height: 250px;
	width: 170px;
	background: #fff;
	left: 30px;
	top: 55px;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid #ddd;
	z-index: 1000;
	font-size: 12px;
}

.timezone-list li {
	height: 22px;
	padding: 3px 0;
	line-height: 22px;
	overflow: hidden;
	color: #666;
	text-indent: 5px;
	cursor: pointer;
}

.timezone-list li:hover {
	background: #f39700;
	color: #fff;
}

.js-terms {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 15px;
	font-family: PingFangSC-Regular, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
}

.js-terms,
.js-terms h5 {
	font-size: 14px;
	font-weight: 400;
	color: #222;
	line-height: 26px;
	text-align: justify;
}

.js-terms h5 {
	padding-bottom: 15px;
	font-family: PingFangSC-Medium, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
}

.js-terms p {
	margin-bottom: 10px;
	text-align: justify;
}

.js-terms i {
	font-style: inherit;
	text-decoration: underline;
	text-align: justify;
}

.js-login__tabs {
	width: 100%;
	height: 45px;
	margin: 40px 0;
	text-align: center;
	font-size: 14px;
	line-height: 45px;
}

.js-login__tabs span {
	display: inline-block;
	width: 53px;
	height: 38px;
	font-size: 24px;
	color: #666;
	position: relative;
	cursor: pointer;
}

.js-login__tabs span.is-active {
	font-weight: 600;
}

.js-login__tabs span.is-active:after {
	width: 100%;
	height: 2px;
	content: " ";
	position: absolute;
	left: 0;
	bottom: -8px;
	opacity: 1;
	background-color: #333;
}

.js-login__tabs span:first-child {
	margin-right: 40px;
}

.gobackLoginOauth {
	position: absolute;
	height: 26px;
	color: #333 !important;
	font-weight: 600 !important;
	font-size: 24px !important;
	margin: 35px;
	border: none;
	padding: 0;
	float: none;
	top: 20px;
	cursor: pointer;
}

.gobackLoginOauth .go-left {
	width: 13px;
	height: 15px;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMi43MTggNy4ybDUuMDkzIDUuMTU4YTEgMSAwIDAxLS4wMDUgMS40MWwtLjAwNC4wMDRhLjk5NC45OTQgMCAwMS0xLjQxLS4wMDVMLjYwMiA3LjkwNGExIDEgMCAwMS4wMDUtMS40MWwuMDA1LS4wMDVhLjk5NC45OTQgMCAwMS4wMjYtLjAyNS45MjkuOTI5IDAgMDEuMDkzLS4xMDZMNi4zODguN2ExIDEgMCAwMTEuNDE0IDEuNDE0TDIuNzE4IDcuMTk5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==)
		no-repeat;
	display: inline-block;
	margin-left: 5px;
}

.gobackLoginOauth .js-login__close_oauth {
	position: absolute;
	top: -40px;
	right: -209px;
	width: 16px;
	height: 16px;
	background: url(https://staticn.jinse.cn/w/img/910ff47.jpg) no-repeat;
	background-size: 16px 16px;
	cursor: pointer;
}

.js-mask__layer {
	background: rgba(0, 0, 0, 0.6);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}

.js-login {
	position: relative;
}

.js-login__form input {
	height: 30px;
	width: 228px;
	border: none;
	font-size: 16px;
}

.js-login__agreement,
.js-login__phone,
.js-login__psd,
.js-login__verification {
	width: 324px;
	height: 50px;
	margin: 0 auto;
	padding: 5px 10px;
	line-height: 70px;
	border-bottom: 1px solid #e1e1e1;
}

.js-login__phone input {
	margin-left: 20px;
}

.js-login__psd {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.js-login__psd img {
	width: 16px;
}

.js-login__psd img:hover {
	cursor: pointer;
}

.js-login__areaCode {
	display: inline;
	display: initial;

	font-size: 18px;
	color: #666;
	cursor: pointer;

	width: 90px;
	height: 40px;
	border-radius: 4px;
	opacity: 1;

	background: #eff5fe;
}

.js-login__areaIcon {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-left: 5px;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uMjcuMjNoNmwtMyA1eiIgZmlsbD0iIzM2MzYzNiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+)
		no-repeat;
}

.js-login__info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 344px;
	height: 50px;
	margin: 0 auto;
}

.js-login__changePhone,
.js-login__forget {
	font-size: 14px;
	color: #4285f4;
	cursor: pointer;
}

input.js-login__submit {
	display: block;
	width: 344px;
	height: 44px;
	margin: 7px auto;
	background: #edf0f2;
	color: #b2bac2;
	text-align: center;
	line-height: 44px;
	border-radius: 4px;
	font-size: 16px;
}

.js-thirdParty {
	width: 340px;
	overflow: hidden;
	margin: 0 auto;
	padding: 20px 0;
}

.js-thirdParty__head {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	color: #999;
	line-height: 40px;
}

.js-thirdParty__head:before {
	content: " ";
	width: 132px;
	height: 1px;
	margin-right: 3px;
	background: #e1e1e1;
}

.js-thirdParty__head:after {
	content: " ";
	width: 132px;
	height: 1px;
	margin-left: 3px;
	background: #e1e1e1;
}

.js-login__weixinlogo {
	display: block;
	margin: 18px auto 0;
	width: 47px;
	height: 47px;
	background: url(https://staticn.jinse.cn/w/img/e4ead97.svg) no-repeat;
}

.js-login__weixinlogo:hover {
	background: url(https://staticn.jinse.cn/w/img/ae53190.svg) no-repeat;
}

input.js-login__captcha {
	float: right;
	width: 83px;
	height: 28px;
	margin-top: 20px;
	background: #f39700;
	color: #fff;
	font-size: 14px;
	line-height: 28px;
	border-radius: 4px;
	text-align: center;
	cursor: pointer;
}

.capBtn {
	width: 106px;
	background: #edf0f2;
	color: #b2bac2;
}

.js-login__agreement {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 10px 0;
	line-height: inherit;
}

.js-login__agreement p {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-login__agreement input[type="checkbox"] {
	width: 15px;
	height: 15px;
	margin-right: 5px;
	vertical-align: middle;
}

.js-login__agreement span {
	font-size: 14px;
	color: #666;
}

.js-login__agreement span i {
	color: #4285f4;
	cursor: pointer;
}

.login-err-alert {
	position: absolute;
	top: 35%;
	left: 33%;
	background: #edf0f2;
	padding: 2px 8px;
	height: 45px;
	text-align: center;
	line-height: 45px;
	border-radius: 4px;
	color: #000;
}

.active-button {
	background: #f29700 !important;
	color: #fff !important;
	cursor: pointer;
}

.input__focus {
	border-bottom: 1px solid #f39700;
}

input::-webkit-input-placeholder {
	color: #c8c8c8 !important;
}

input:-moz-placeholder,
input::-moz-placeholder {
	color: #c8c8c8 !important;
}

input:-ms-input-placeholder {
	color: #c8c8c8 !important;
}

.js-login__head {
	display: flex;
	align-items: center;
	padding: 35px;
	cursor: pointer;
}

.js-login__head .goBack {
	display: inline-block;
	width: 13px;
	height: 15px;
	margin: 0 5px;
	background: url(https://staticn.jinse.cn/w/img/4f70426.svg) no-repeat;
}

.js-login__head .title {
	color: #333;
	font-weight: 600;
	font-size: 24px;
}

.js-resetPwd {
	width: 400px;
	height: 540px;
	background-color: #fff;
	position: relative;
}

.js-protocol {
	width: 814px;
	height: 100%;
	padding: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	background: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.js-protocol h3 {
	text-align: center;
	font-size: 18px;
	color: #222;
	margin-top: 50px;
}

.js-protocol p {
	width: 746px;
	margin-bottom: 10px;
	margin-left: 25px;
	line-height: 30px;
	font-size: 14px;
	color: #222;
}

.js-protocol section {
	margin: 9px 56px;
	line-height: 32px;
	font-size: 14px;
	color: #222;
}

.gobackLogin {
	margin: 35px !important;
	color: #333 !important;
	font-weight: 600 !important;
	font-size: 24px !important;
}

.go-left {
	width: 13px;
	height: 15px;
	background: url(https://staticn.jinse.cn/w/img/4f70426.svg) no-repeat;
	display: inline-block;
	margin-left: 5px;
}

.js-new-resetPwd {
	background-color: #fff !important;
}

.p-zhan {
	height: 60px;
	width: 100%;
}

.relative {
	position: relative;
}

.relative .timezone-list {
	left: 2px;
	top: 55px;
}

.js-loginBox {
	width: 740px;
	height: 540px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99;
}

.js-login__close {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 16px;
	height: 16px;
	background: url(https://staticn.jinse.cn/w/img/910ff47.jpg) no-repeat;
	background-size: 16px 16px;
	cursor: pointer;
}

.js-login__cover {
	float: left;
	width: 340px;
	height: 540px;
	position: relative;
}

.js-login__cover img {
	width: 100%;
	height: 100%;
}

.js-login__conent {
	float: left;
	width: 400px;
	height: 100%;
	background-color: #fff;
}

.js-login__qrCode {
	position: absolute;
	left: 100px;
	bottom: 89px;
	width: 136px;
	height: 179px;
	box-sizing: border-box;
	padding: 15px 14px 57px 15px;
	background: #fff;
	box-shadow: 0 0 20px 0 rgba(26, 26, 26, 0.5);
	border-radius: 8px;
	text-align: center;
}

.js-login__qrCode img {
	width: 107px;
	height: 107px;
	margin-bottom: 4px;
}

.js-login__qrCode p {
	font-size: 14px;
	font-family: PingFangSC-Regular;
	font-weight: 400;
	color: #999;
}

.js-login__downBtn {
	position: absolute;
	left: 100px;
	bottom: 38px;
	width: 138px;
	height: 39px;
	line-height: 39px;
	border: 1px solid #979797;
	border-radius: 4px;
	font-size: 18px;
	font-family: PingFangSC-Medium;
	font-weight: 500;
	color: #fff;
	text-align: center;
	cursor: pointer;
}

a {
	transition: all 0.1s;
}

.js-topTip__box {
	width: 100%;
	left: 0;
	background: #1f1f22;
}

.js-topTip {
	display: flex;
	width: 1170px;
	height: 32px;
	line-height: 32px;
	margin: 0 auto;
	background: #1f1f22;
}

.js-topTip .js-topTip_left {
	display: flex;
	height: 32px;
	align-items: center;
}

.js-topTip .js-topTip_left span {
	font-size: 14px;
	cursor: pointer;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 32px;
	height: 32px;
	color: #a0a0a9;
}

.js-topTip .js-topTip_left a {
	text-decoration: none;
	color: #a0a0a9;
	line-height: 16px;
	height: 16px;
	border-bottom: 1px solid #a0a0a9;
}

.js-topTip .js-topTip_left a:hover {
	color: #fff;
	cursor: pointer;
	border-bottom: 1px solid #fff;
}

.js-topTip .js-topTip_right {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}

.js-topTip .js-topTip_right ul {
	display: flex;
	align-items: center;
}

.js-topTip .js-topTip_right ul li {
	padding-right: 13px;
	padding-left: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	height: 12px;
	color: #a0a0a9;
	border-right: 1px solid #a0a0a9;
}

.js-topTip .js-topTip_right ul li a {
	color: #a0a0a9;
}

.js-topTip .js-topTip_right ul li a:hover {
	color: #fff;
	cursor: pointer;
}

.js-topTip .js-topTip_right ul li:last-child {
	border: none;
	margin-right: 0;
	padding-right: 0;
}

.container {
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0.98;
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
}

.js-menu__box {
	padding: 0;
	width: 1175px;
	height: 30px;
	margin: 44px auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.js-menu__box .js-logoBox-r {
	height: 24px;
	width: 24px;
	cursor: pointer;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKrSURBVHgB7ZrBbdswFEA/hRZobxkhI7gbxBukhwZNLxFH6AR1Jmg3kHMoirSHdoN6g2SDaASfcjTDT0m2rIiySP5P0UgeoFgRKfE/E7bJTwK88koSFEVxUhQ/Z93r4nlFrJStpbws4Ugoil+nQmT/9empPkqlHj9IKddYlrUrLpe3d0K80Uf2cHPz5xscAR05qF7f50151q6oX7ZdrNRmkbpkj1zNpmzOWj34Drt03a6WsqRNTsd8LeWXf83/W0EpP66VEnM4AslhuctF+1rPl8zvmRAKbz7ZqyiyxdXVp2uYGBc5RPQ/JE1JVzlE2B+WlqSPHGIVrB6ahqSvHDIoWD18WskQOeSgYNXINJKhcsgowaqxuJIUcshowarROJJUciY2cIRbklLOxAUecElSy5mYwBNqSQ45Ew8EQCXJJWdigUBCJTnlTBxAgK8kt5yJAYhwlYwhZ9oHQsZKxpIzbQMxhyRjypl2gQGbpOaHPs4hkhzCIogMSO7BKYewCSKHJLnlEFZBpChuz4WAv89LxCrPL+bATAaMVF8o8L2/VJ3FyNYxfgZtSdlOAMyTZpYetMupJUTOu5ILDv3O5flnGTu5TDySGfcjHjP9QTgWdRuhxJIkmk34Db9iSBLMB8PGltySgTN6moEzp2RAToZ2VsAl6ZlV45nycEh65EV553PUko6Z7TiTVUpJh7WJuDNxKsmRq0tx5XbthkuOWB+cRm7XfpjkgRXeaeV2cfhLDqzRpyG3i8dP0rLLIi25Bh/Jnn0yaco1uEqK/ZvTlmtwkcw6FZKXQ6S8uB+bGdj2YP2u3HVuSE6uja0nddxzHfcKz1s9+LbUf8pWpaTlEFtPas6ak85uw81cKfW1fgcWcAS0JMv6Esoum/KgCW9KVBt6sxnA40rW25lfBE8CZLqNMpjqTwAAAABJRU5ErkJggg==);
	background-size: 24px 24px;
}

.js-menu__box .js-logoBox-r:hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADsSURBVHgBtdOLDYIwEAbgu07AAkBhAlZgAnUTNxAnMI7gBmzACG6ghAVkAs4eQUKItNcaLyGBtnzp4y/APypJ8ovWuoDA0jo/pWn+NEakuAGRCiLVhKCMEUFlnlvbtv0HPABQ64susHPXPapVp47SNLubqb8k6LRMMttVWQbJUBEmRb0wFyrB0IYSYWOGaMShBFC7zQOQgCt0nKUL41K2Ts4VItbTZ6/UUIOjlH2Gc86u0pwqAcbLPJrwlyHhn7Fvp+kbfisWhEpDK0J9b4AVDbpOG+gYbG7gaLhCu4UublQ2N8IPxf/Hsd7z+xumnaF3+oYTMwAAAABJRU5ErkJggg==);
}

.search-input {
	width: 770px;
	position: relative;
	margin: 90px auto 55px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.search-input .search-input-f {
	width: 770px;
	height: 66px;
	position: relative;
}

.search-input .search-input-f > input {
	width: 770px;
	height: 100%;
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	box-sizing: border-box;
	border-radius: 50px;
	font-size: 24px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: #1f1f22;
	caret-color: #ffb100;
	text-indent: 24px;
	font-weight: 400;
}

.search-input .search-input-f > span {
	position: absolute;
	right: 34px;
	top: 21px;
	cursor: pointer;
	height: 24px;
	width: 24px;
}

.search-input .search-input-f > span img {
	height: 24px;
	width: 24px;
}

.search-input .pre-search {
	width: 770px;
	position: absolute;
	top: 79px;
	left: 0;
	background: #fff;
	border-top: none;
	border-radius: 20px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.search-input .pre-search ul {
	width: 770px;
	box-sizing: border-box;
	padding: 17px 0;
}

.search-input .pre-search ul li {
	box-sizing: border-box;
	width: 770px;
	padding: 0 34px;
	height: 48px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-size: 14px;
	line-height: 48px;
	color: #000;
	position: relative;
	cursor: pointer;
}

.search-input .pre-search ul li:hover img {
	opacity: 1;
}

.search-input .pre-search ul li img {
	position: absolute;
	right: 0;
	height: 26px;
	width: 76px;
	top: 14px;
	opacity: 0;
}

.search-input .pre-search ul .li-hover {
	background: #f8fafd;
	font-weight: 500;
}

.search-history {
	width: 770px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto 34px;
}

.search-history > p {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	line-height: 20px;
	color: #1f1f22;
}

.search-history > span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a9;
	cursor: pointer;
	transition: all 0.2s;
}

.search-history > span:hover {
	color: #1f1f22;
}

.search-history-word {
	width: 770px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto 35px;
}

.search-history-word > p {
	margin-right: 34px;
	margin-bottom: 21px;
}

.search-history-word > p > span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a9;
	cursor: pointer;
	margin-right: 5px;
	transition: all 0.2s;
}

.search-history-word > p > span:hover {
	color: #1f1f22;
}

.search-history-word > p .shut-img-box {
	display: inline-block;
	cursor: pointer;
	transition: all 0.2s;
	width: 10px;
	height: 10px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKrSURBVHgB7ZrBbdswFEA/hRZobxkhI7gbxBukhwZNLxFH6AR1Jmg3kHMoirSHdoN6g2SDaASfcjTDT0m2rIiySP5P0UgeoFgRKfE/E7bJTwK88koSFEVxUhQ/Z93r4nlFrJStpbws4Ugoil+nQmT/9empPkqlHj9IKddYlrUrLpe3d0K80Uf2cHPz5xscAR05qF7f50151q6oX7ZdrNRmkbpkj1zNpmzOWj34Drt03a6WsqRNTsd8LeWXf83/W0EpP66VEnM4AslhuctF+1rPl8zvmRAKbz7ZqyiyxdXVp2uYGBc5RPQ/JE1JVzlE2B+WlqSPHGIVrB6ahqSvHDIoWD18WskQOeSgYNXINJKhcsgowaqxuJIUcshowarROJJUciY2cIRbklLOxAUecElSy5mYwBNqSQ45Ew8EQCXJJWdigUBCJTnlTBxAgK8kt5yJAYhwlYwhZ9oHQsZKxpIzbQMxhyRjypl2gQGbpOaHPs4hkhzCIogMSO7BKYewCSKHJLnlEFZBpChuz4WAv89LxCrPL+bATAaMVF8o8L2/VJ3FyNYxfgZtSdlOAMyTZpYetMupJUTOu5ILDv3O5flnGTu5TDySGfcjHjP9QTgWdRuhxJIkmk34Db9iSBLMB8PGltySgTN6moEzp2RAToZ2VsAl6ZlV45nycEh65EV553PUko6Z7TiTVUpJh7WJuDNxKsmRq0tx5XbthkuOWB+cRm7XfpjkgRXeaeV2cfhLDqzRpyG3i8dP0rLLIi25Bh/Jnn0yaco1uEqK/ZvTlmtwkcw6FZKXQ6S8uB+bGdj2YP2u3HVuSE6uja0nddxzHfcKz1s9+LbUf8pWpaTlEFtPas6ak85uw81cKfW1fgcWcAS0JMv6Esoum/KgCW9KVBt6sxnA40rW25lfBE8CZLqNMpjqTwAAAABJRU5ErkJggg==);
	background-size: 10px 10px;
}

.search-history-word > p .shut-img-box:hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADsSURBVHgBtdOLDYIwEAbgu07AAkBhAlZgAnUTNxAnMI7gBmzACG6ghAVkAs4eQUKItNcaLyGBtnzp4y/APypJ8ovWuoDA0jo/pWn+NEakuAGRCiLVhKCMEUFlnlvbtv0HPABQ64susHPXPapVp47SNLubqb8k6LRMMttVWQbJUBEmRb0wFyrB0IYSYWOGaMShBFC7zQOQgCt0nKUL41K2Ts4VItbTZ6/UUIOjlH2Gc86u0pwqAcbLPJrwlyHhn7Fvp+kbfisWhEpDK0J9b4AVDbpOG+gYbG7gaLhCu4UublQ2N8IPxf/Hsd7z+xumnaF3+oYTMwAAAABJRU5ErkJggg==);
}

.js-hot-search-list {
	margin: 0 auto;
	width: 770px;
}

.js-hot-search-list > p {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	line-height: 20px;
	color: #1f1f22;
	margin-bottom: 34px;
}

.js-hot-search-list .js-hot-search-list-word {
	display: flex;
}

.js-hot-search-list .js-hot-search-list-word > ul {
	width: 50%;
}

.js-hot-search-list .js-hot-search-list-word > ul > li {
	padding-right: 55px;
}

.js-hot-search-list .js-hot-search-list-word > ul li:first-child > span {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFPSURBVHgB7ZjRTcMwEIb/uzBAR/AGwFsFQioT0A2gI7AACRLvrMAGzQYUgRBvlA0yQhZIjruoUYOKCJXS5B7yPVi2JcufHPuUO8I/kGQ6A0dXIMwgCDo1QZeQpCjKW0o+MsKfImc3YMS6IuDw5CiLU/pdZBpA0VJP5AR9IlhFO3MP59cApyoT0DeEyY8TkuRiDi6XGBDayuhn4ugTXV/YPeFtj58xsEylYU31mkABDuBNG8MJXAU9UIATGMRzOILBdAxHsEbHfqNxC3apB3/qTRjOGIXaGIXaGIXa8CaU+xIirH0JlfLlS0g49SSUUfK2ciQk99Z6Ecro7v3JOh6ELIW+rAfDC5W8sCJDPTShHMOg+4rKvKbNSfuFXaNvbE+rdGzuTZMjSLHQSkdfKXRmr4niXZGaKrev8vqIHyHUdUqU6w6ZRuAXC3oWZ9oWfAOQOVn9W6TQPgAAAABJRU5ErkJggg==);
	background-size: 100% 100%;
}

.js-hot-search-list .js-hot-search-list-word > ul li:nth-child(2) > span {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfSURBVHgB7ZhBTsJAFEDffGEnBG7ADZQb4AnkBsoKWbjwBIYTuDBK3HkDewPxBOINuIENuATGP41VBEM1wfYveIu203SSlz//d/rr+AXTa1quzLGHFgsaOGpsE0c081zUe4zdpucmN5yKcKkiDf6feAbNH4VeBzTKjgfvOSRfhnurd97uOJEFEflEZZXatwhNbmk7jQwF8ikUlqnked56wv4RSS/K8Fi0TCARCtXki8mZNRKhUNoYQaYDWlaiE9AKp40hRBwHGEJ0b8r7bbwRsVDqywjG2AllsRPKYieUhTWh2JrQyJSQbvQvpoRkRmRGSL/JxpVzhpYi1A8HE0IhOtUe9+G6cCHtjuM5HKXjEkWzR6feZZwOReMVUwAhMtqldqrdpG3nS0gYkTeO0dzR3P/Im2VK+l+mk1cLHZJXT/3q2bpIStLbJ32940pnbLUlSpZFVMLzpMOo0mOYNecdyMJWzOLN0hcAAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

.js-hot-search-list .js-hot-search-list-word > ul li:nth-child(3) > span {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE1SURBVHgB7ZjbjcIwEEWvnQYogQ52twO2AtgKdilhK0BUQAt0gD/4ByogdOAS3AAJM8ZI4WmQgj0fOZLJJCLR0bVjaaLwBPUCA2gMqRzQ6NPooU0UDPb4Vz+wCo9F/khkEiTejUOFL3VHpE8iCyo/kRKFdXF5rV7ilw4GaVK5pHeWECUzCslkQzVkeJq2aHvBvohuVCtkljlqILxNedbMFTr8TiAE7Tc9IekwnNAIgtAo8AFBaNSJd+MIPGXZX/UmGsLohGJ0QjE6oRjShJwsoRqlNKGdtCkzkoQsNYprOUIVpnyQIsTpzLmQIMQt9PfpJL9QhTF/ZDidspBDHlyQMc2LmnrXEukp/ZeOsG5wJrTHGOlSsj6VoZext/7ge3vf1xeY0U7Zdkvk6JmWxoZqw/tM7IYDdfdFor4RiC0AAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

.js-hot-search-list .js-hot-search-list-word > ol {
	width: 50%;
}

.js-hot-search-list .js-hot-search-list-word li {
	cursor: pointer;
	margin-bottom: 24px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	color: #000;
	display: flex;
	transition: all 0.2s;
}

.js-hot-search-list .js-hot-search-list-word li:hover {
	color: #ffb100;
	font-weight: 500;
}

.js-hot-search-list .js-hot-search-list-word li > span {
	display: inline-block;
	width: 18px;
	height: 17px;
	text-align: center;
	line-height: 17px;
	font-family: DINOT, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	color: #fff;
	margin-right: 13px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFlSURBVHgB7ZfRTcMwEEDv3PafEboBFKhaf1QKE9ANoCMwAeoErNANmg0IqtqoKihlg4zQ70jxcU4aCFAakEJyH3lSIsdy4if77PgQfsFisXE6nfY1ADnGUBcRTqBE+HsuUXSntQ7xWMPl8uVWKbjnYhf+GZbasVTvoJDvBzwKNCeiM6gU9Fpfq9br4MYY40IFo/JNh0Ph0witVs9jRJxDjbwL2WkiMkHZAftX1EfRPNYtY0mE7GqCGmLmEInQfmmLQPn+xgEho2NRRDgGQShEdQqCUNXvxsdREpZ6HgXCaISKaISKaISKECVkz9WihPivsRUmBK+ihOKYXElC4WjU9yQJTe1NilCo9cXMFmoX4kDeAURX2XMbaqbVoslgoMPsWaWG1bPvl2Uu3Xw9nxhxCxVj+0SMelnc5OEpwwnvkFWl0CFf0+HwfPZTgyS3T/P6+IHNS02J0mmhkLt5QjSu1n2v6J03oLhzk8C7LdcAAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

a {
	transition: all 0.1s;
}

header {
	width: 1170px;
	margin: 0 auto;
}

.header_height176 {
	height: 176px;
}

.header_height88 {
	height: 88px;
}

.js-menu-bottom,
.js-menu-top {
	width: 1170px;
	margin: 0 auto;
}

.js-menu-bottom_height60 {
	height: 60px;
}

.js-menu-bottom_height56 {
	height: 52px;
}

.js-menu__box {
	display: flex;
	align-items: center;
	position: relative;
	width: 1170px;
	margin: 0 auto;
}

.height84 {
	height: 84px;
}

.height56 {
	height: 56px;
	box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.05);
}

.js-menu__pic {
	height: 52px;
	display: flex;
}

.js-menu__pic .js-menu-split__line {
	background: #dedee1;
	width: 1px;
	height: 34px;
	margin-top: 11px;
	margin-left: 21px;
}

.js-menu__pic .js-menu-img__pic {
	height: 52px;
	margin-left: 21px;
}

.js-block {
	position: relative;
	display: block;
	height: 56px;
}

.js-header {
	position: absolute;
}

.js-header,
.js-header_fixd {
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	color: #fff !important;
	background-color: #fff;
	box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0.05);
	z-index: 20;
}

.js-header_fixd {
	position: fixed;
}

.js-header-container {
	width: 1170px;
	margin: 0 auto;
	border-bottom: 1px solid #eaeaef;
	position: fixed;
	z-index: 3;
	top: 0;
}

.js-header__func {
	justify-content: space-around;
	margin-left: auto;
	position: relative;
}

.js-header__func,
.js-logoBox {
	display: flex;
	align-items: center;
}

.js-logoBox {
	height: 56px;
	justify-content: center;
}

.js-logoBox--logo__big {
	width: 170px;
	height: 32px;
}

.js-logoBox--logo__small {
	width: 120px;
	height: 22px;
}

.js-logoBox--logo——new_skin {
	width: 200px;
	position: relative;
}

.js-logoBox--logo——new_skin img {
	-o-object-fit: cover;
	object-fit: cover;
	display: block;
}

.js-nav {
	position: relative;
	height: 100%;
	display: block;
	display: flex;
	justify-content: space-between;
}

.js-nav .js-header__more {
	position: relative;
	font-size: 20px;
	line-height: 20px;
	color: #d8d8d8;
}

.js-nav .js-header__more:hover {
	cursor: pointer;
}

.js-nav .js-header__more:hover .js-nav__more {
	display: block;
}

.js-nav-top {
	position: relative;
	height: 100%;
	display: flex;
	justify-content: space-between;
	margin-left: 60px;
	margin-right: 63px;
	box-sizing: border-box;
}

.js-nav-top .js-header__more {
	position: relative;
	font-size: 20px;
	line-height: 20px;
	color: #d8d8d8;
}

.js-nav-top .js-header__more:hover {
	cursor: pointer;
}

.js-nav-top .js-header__more:hover .js-nav__more {
	display: block;
}

.js-nav-top__item {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: #1f1f22;
}

.js-nav-top__item:hover {
	color: #ffb100;
}

.js-nav-top__item.is-active {
	color: #ffb100;
	border-bottom: 2px solid #ffb100;
}

.margin30 {
	margin: 0 30px 0 0;
}

.margin57 {
	margin: 0 57px 0 0;
}

.js-nav__item {
	position: relative;
	height: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
	color: #1f1f22;
}

.js-nav__item .js-nav__item-box,
.js-nav__item {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.js-nav__item .yellow_nav_box {
	color: #ffb100;
	transition: all 0.1s;
}

.js-nav__item .gray_nav_box {
	color: red;
	color: #1f1f22;
	transition: all 0.1s;
}

.js-nav__item.is-active {
	color: #ffb100;
}

.js-nav__more {
	display: none;
	position: absolute;
	top: 58px;
	left: -10px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	width: 94px;
	padding: 5px 20px;
	text-align: center;
	background: #fff;
	border-radius: 2px;
}

.js-nav__more:before {
	position: absolute;
	top: -7px;
	left: 15px;
	width: 10px;
	height: 10px;
	transform: rotate(-45deg);
	background: #fff;
	border: 1px solid #eaeaef;
	border-width: 2px 2px 0 0;
	content: " ";
}

.js-nav__more a {
	display: block;
	padding-bottom: 5px;
	font-size: 16px;
	color: #121115;
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.js-nav__more a.is-active,
.js-nav__more a:hover {
	color: #ffb100;
}

.js-func {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	height: 60px;
	margin-right: 21px;
}

.margin0 {
	margin-right: 0;
}

.js-func__item {
	display: inline-flex;
	justify-content: space-around;
	align-items: center;
	width: 86px;
	font-size: 12px !important;
}

.js-func__item:hover {
	color: #ffb100;
}

.js-func__item:hover.js-icon-search {
	width: 53px;
	height: auto;
	background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1Ljg3MiAxNC44MTJsLjAxNi4wMTUgMy44OSAzLjg5YS43NS43NSAwIDExLTEuMDYxIDEuMDZsLTMuODktMy44ODlhLjc2OC43NjggMCAwMS0uMDE1LS4wMTYgOSA5IDAgMTExLjA2LTEuMDZ6TTkgMTYuNWE3LjUgNy41IDAgMTAwLTE1IDcuNSA3LjUgMCAwMDAgMTV6IiBmaWxsPSIjMmQyZDM1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
		0 0 no-repeat;
}

.js-func__app {
	position: relative;
}

.js-func__app:hover .js-func__item--app_big {
	background-image: url(https://staticn.jinse.cn/w/img/24d66d7.png);
}

.js-func__app:hover .js-func__item--app_small {
	background-image: url(https://staticn.jinse.cn/w/img/a7e34e0.png);
}

.js-func__app:hover .js-func__text {
	color: #ffb100;
}

.js-func__app:hover .js-func__download {
	transition: opacity 0.1s;
	display: flex;
	opacity: 1;
}

.js-func__item--app_big {
	background-image: url("https://zy.metaera.media/assets/images/tab/tab_phone1.png");
	width: 42px;
	height: 42px;
}

.js-func__item--app_big,
.js-func__item--app_small {
	background-size: 100%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.js-func__item--app_small {
	background-image: url("https://zy.metaera.media/assets/images/tab/tab_phone1.png");
	width: 30px;
	height: 30px;
}

.js-func__download {
	display: none;
	position: absolute;
	top: 60px;
	z-index: 11;
	justify-content: space-between;
	width: auto;
	padding: 20px;
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.js-func__download .qr {
	display: flex;
	flex-direction: column;
}

.js-func__download .qr img {
	width: 98px !important;
	height: 98px !important;
}

.js-func__download .qr .js-func__download_des {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #1f1f22;
	display: inline-block;
	text-align: center;
	padding-top: 5px;
}

.js-func__download .qr i {
	text-align: center;
	display: block;
	padding-top: 6px;
}

.js-func__download .qr i:last-child {
	font-weight: 700;
	padding-top: 0;
}

.js-func__download .line {
	position: absolute;
	left: 146px;
	width: 1px;
	height: 120px;
	background: #eaeaef;
}

.js-func__text {
	margin-left: 5px;
	font-size: 12px;
	color: #909099;
}

.js-func__item--search {
	width: 20px;
	height: 20px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAADCElEQVR4AdxYNbTUQBQNDi1WI32LtEjf4NB/r3HYei3JfJsJ7hAapMfdmy99ibvbu+esvJ3VeM5/57wmO3lz8/yu4VUGBw8ttW2nm/S0ZamnpK9Iv5f0VenZGdNUPcXiyDIjDslkMtMJ0BbLcu7Q5X9J/3WoOHvXNOXWjRvdGZGAE8JZR8DGcWFAnbAsuT40YMeOHZtLRiV5TvfYD7roqm3LgaEhuXp0dHRxJuPOhuZyo4vJW6vxm22rKzirA6XnCrYDek0sImOPNOPvyPjBfF4t7NQOzpL3D9B7bzVbj4U4uigIuInar3YuwjuGT8G7ZMetBSknPYOE6zXP/aFq3GuEJGRvD2xyTxaLxXleDEgOjr5ysxGyUDQ21YKUTsfVyguCey4CkLtrw31ofds+h1bCc86IVgDyAs9H123RJ9GEebXygohKUCCWVa1uIQ5tbXoYE4L1qYNGTEJ37WdRu9t0trLx9YP3uahlaGhogWlWmvlfIdTyRuHtZrlw1YhZ6N7L1TCrnkYAT1fdLAcSANjPHHS20YGn5QOYrXEDpOJYxQr0eSOAr8oHMPjjBqiUWsgAvmkE8Hv5ADaSBADOYgB/phqgbQNgixDncomH+G3LIsGymUAVr2Tz/0WjA2eSbDMU1r6qB536NgP2xXLgSoKNGtpv6AJqmOSoKxcpVr2mNBXUkLn5QEz4EL39zHsPWhyUW1mY38azbgm2bkHljqaHQao1ouTGkHvnOWduubBCQKo1argnQnC7OPexbbm205JX/EUQnAjAbdSYXd4r7Xysgdwdsuc4uFe40wdXkJMacb+A58EKguccV+fwv3//pnkGqXkS+hYcAr3L40q/n1drSV8FBgnGD1KtfzE4RKn792PZxLAnnXX9+vWZaPKYrRhfpTPftfcR3jzCClCBQUJAqlnIfSuBnuDVCjChgUSPAm8lI/e8/IFZ+qfiAZow73Phg2QCakjag80DHIL0DelPLJulPH1R+q2/8Wz1DjJ+mSIgkSIWfkszyE94njqQ8BzAUT7/XwsAlrqtxD4mUtAAAAAASUVORK5CYII=);
	background-size: 100%;
	background-repeat: no-repeat;
}

.js-func__item--search:hover {
	background-image: url(https://staticn.jinse.cn/w/img/e33d424.svg);
	background-size: 110%;
}

.js-func__publish {
	position: relative;
}

.js-func__publish:hover .js-func__item--publish {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAYAAADpE0oSAAADmklEQVR4Ac1WA9A1RxDs2LZt2yrEtm2WY9vJh1Ns27Zt206eX3SZrtz9X9ervfue6++qfbiZ7b6dnZ1ZELUICxYD3FL08YeNuEfjD2pQa0TUx6/i0OvxKzWRrDTu56AmNLwVH6uhRyC3hh36JpmTQqz0h4eNkIPiAGYoBdiB38iAamUKq6jZ/k1CdGAO6ev04XdXhMs+9hGfYDRSW/W/XREueNhXkiJsinRsF66nf34cxuRoQNmSKrVb2I/NIf2aPvyGA+QW4TpsT14b88DDU7aqreMBTAQBk6poot+fjcngBl9wuYKPc/gNAbkqxmkcT0kevEbhXdMH8gK/2XdkYV4jBsZBi+AcziWHCf7WyE9NECZ0jD34c8QoTh4+te+TzHl98zvRfj9uvz+1UUoG7Y/TRp/E91MXFzVsu46G4jcfc3MPzfhRD8okOY+lBvJQYHnz8ECngiUf91cirIpmkYTs98auYs8vLYfYvGDdJbYs5eBvPqPN0eV+J1dzoj7Wswk1eeuK7fNpv4aYCqOAPvTlHBGvkRN54NtLt2L2fVW+CMuhRXAO52o3KgxhQbgQn4Bx7c1eVNGKj9nQJjhXxclNDde+7qHhbX6l+SvXsFMDijjGOGZ4X87uaUjwkVUee3bHHwEWQD5Y4RY1obs4BwnIJSF/n1raCNbQ7JVEItlAEoWHRqtW5vdkUvkGNOE026mlYT5LwnEpEvwygClNsCa2nZGBQoC95OWr2nDIKRxnqfBjqYFnsuF4nSOEP8QepnFde8z2s/idCgE5RfgxFf42NfBIQRBficns+RfSkyNHYl4h9o+/Og+TQEBOEf5WV1VNDbGrJwfYRDL+H72NFkOsk97JOFzFgpxir6pwLU+YMNutMvmtOMQE7LVyGjiuA5EvXFPh71yhVvxyEWY3e0EIjrC9O157eGkYM0PgCjW1dDVPanLlNI/DhKCsNd22Y38oMpKLWiOEIc7U44QMxDdiPBN8mX46Cj6ec5ZDx3GiFlJUA6zpLCAO/B5gefP5W1b+Z+kiLAkHXAWkaFpSdbJLZkaiXegqCIqskkktKHi7bLZJxJdgCl5jze+zOMSkzTYJasAF7lWzbbESYEtLmo2abYvkRhaqF2Eec/qpBxeBn8iNPFQjrGXhqXfr6kMuckKRJ8637MJl76emRTXssuctD87V8LYCzfb3WxB9n3PQDfDsscgU/r8wPMl6a6OWjO/4jDb60BdN4D/ZbQXOv6vwnAAAAABJRU5ErkJggg==);
}

.js-func__publish:hover .js-func__text {
	color: #ffb100;
}

.js-func__publish:hover .js-func__work {
	display: block;
}

.js-func__item--publish {
	width: 15px;
	height: 20px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAYAAADpE0oSAAADj0lEQVR4Ac1XA5TsShDNt23btnXwbdvm8bcfB8l4gmfbtm3btq26y/ty0pvMus/pnZnCvalKdVWvhpVK2dfGYlZ72ZtlH6ygvRkc4GLSDVBW0t4ATg1PAUEl7/Yap9cw6j2kVdACNqdd4ydROYnuPsOwnisJOJFInGMY5jv4VNkwly8xSGUfyLexv1VHZE2CDT7Lidj+gmxMP9B43D5QLsTxuPllcQ1YVhDQ6k0sYLsLf6TT6ZM93t1zxWm0/iwBdBls8OmlBzYFsBvvcCI9yVDDcF6XyjyOnVBUIA2HwyepiONx5y7xj+CT5cBKJJzXgU08EwXU/NDjgG8Uua3rmUfE9wgt93WErjuPAANYbnxwwgjkf4hgDyl5L5DU/Cfv+mmJ/F+JfJDsBSLfho3vkEEHG/n+H2QKrD2i+931LrOXI52inFsBLXIusMHh096yD0mUvctOaPaSc/2gFnTlp9Xa5J4q8tQNkknrZUwXVCk2vkMGnceU2wSsIJyo4KfEYRc57xDnWnXqWKf5+cIGtvAh/13AVDrRbObLwFI6HoEXfODL0ygalRnstf75558jxWAMk+p69iKtlAu+LvIx4PB6yk84vcEj9Ymc0g4OjdfBgwePEMWsYgOzFncekXUW2TU+PGJr3ijvsyt3PmBRQLPARWlxHuHq5UKS45AoGHd9/bqVkAzJ78V2gguOqx1c3OBDNAgaULSnHl7h5vvq02B+Rg+/kwcOMEkX4iM0sFCBM+maOhGaKqszmcwZXtce0a8j8JqsB2ahDlwMvqJQgSPFTuFwk5NEvpgcbY+z35hI5+m6foL7mJJ+hUap2KmeyYio3gvkuD+ZrPcQ+T5RfCfDzj5V0iwGF0e8S0VMUXUg8qmWZR1TUPGzSN7S7xIALo54pSLVFLVzsei3EMAv4vc3z/BQKH2+ly+nGlxEbA9RFBcv2P1EANs5U6L7mm1VxQUujriu13FyrzZt2hwlKR/nMf5GerVDr+MELnp/9R51NxB1DzbvFpt9ZC83F+dWL1tuIPSQj7Je2TIVN8q4Z0PgpWiZ4HLdOszXgw6JunXrn1JwnV0o1X1i0CEBDlXbGxlwLGIgvCrn+7mgYxHYmrr6nCvEaG0FXATWAttntNmPieHucrz67AYmmfmSry2Hy97awKScdnrnOW/4UnpzWlzts3IgnUXVW+Z1REGTCaHlFfT2Xdj4Dhl0sAn6v9Yh2kB5W9efnrEAAAAASUVORK5CYII=);
	background-size: 100%;
	background-repeat: no-repeat;
}

.js-func__button,
.js-func__work {
	display: none;
	position: absolute;
	top: 60px;
	right: 10px;
	padding: 6px 15px;
	box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
	font-size: 14px;
	border: 2px solid #eaeaef;
	background-color: #fff;
}

.js-func__button:before,
.js-func__work:before {
	width: 10px;
	height: 10px;
	transform: rotate(-45deg);
	background: #fff;
	border: 1px solid #eaeaef;
	border-width: 2px 2px 0 0;
	position: absolute;
	top: -7px;
	right: 13px;
	content: " ";
}

.js-func__workItem {
	width: 66px;
	height: 30px;
	line-height: 30px;
	color: #121115;
}

.js-func__workItem:hover {
	color: #ffb100;
}

.js-func__workItem-border {
	border-bottom: 1px solid #eaeaef;
}

.js-func__item--user_big {
	width: 42px;
	height: 42px;
	background-image: url(https://staticn.jinse.cn/w/img/30c2a9b.png);
}

.js-func__item--user_big,
.js-func__item--user_small {
	background-size: 100%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.js-func__item--user_small {
	width: 30px;
	height: 30px;
	background-image: url(https://staticn.jinse.cn/w/img/45f20b7.png);
}

.js-func__user {
	position: relative;
}

.js-func__user:hover .js-func__item--user_big {
	transition: all 0.1s;
	background-image: url(https://staticn.jinse.cn/w/img/1e45614.png);
}

.js-func__user:hover .js-func__item--user_small {
	transition: all 0.1s;
	background-image: url(https://staticn.jinse.cn/w/img/b1c6d74.png);
}

.js-func__userMenu {
	display: none;
	opacity: 0;
	position: absolute;
	top: 60px;
	left: -40px;
	bottom: auto;
	z-index: 11;
	width: 124px;
	box-sizing: border-box;
	padding: 8px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.js-func__userMenu a {
	display: flex;
	height: 40px;
	line-height: 40px;
	margin: 0 5px;
	text-align: left;
	align-items: center;
	justify-content: left;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #1f1f22;
	width: 98px;
}

.js-func__userMenu a .dian {
	display: inline-block;
	height: 4px;
	width: 4px;
	background: #333;
	margin-right: 5px;
	border-radius: 50%;
}

.js-func__userMenu a .con {
	display: inline-block;
	flex: 1;
	border-bottom: 1px solid #f5f5f5;
	height: 40px;
	line-height: 40px;
	box-sizing: border-box;
}

.js-func__userMenu a .no_border,
.js-func__userMenu a:last-of-type {
	border: none;
}

.js-func__userMenu a:hover {
	color: #ffb100;
}

.js-func__userMenu a:hover .dian {
	background: #ffb100;
}

.js-func__userMenu .a_flex {
	align-items: center;
	justify-content: left;
	border-bottom: 1px solid #f5f5f5;
	width: 98px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-box-orient: vertical;
}

.js-func__avatar_big {
	width: 40px;
	height: 40px;
}

.js-func__avatar_small {
	width: 30px;
	height: 30px;
}

.js-func__avatar {
	border-radius: 50%;
	position: relative;
}

.js-func__avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.js-func__avatar .red-dot {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	position: absolute;
	right: 4px;
	bottom: 0;
	background: #e44d66;
}

.js-userBox {
	width: 42px;
}

.js-userBox:hover .js-func__userMenu {
	display: block;
	opacity: 1;
}

.js-authBox {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: pointer;
	position: relative;
	min-height: 75px;
	margin-left: 30px;
}

.js-authBox.is-user:hover > .js-userMenu {
	display: block;
}

.js-authBox__avatar {
	border-radius: 50%;
}

.js-authBox__avatar--user,
.js-authBox__avatar {
	width: 42px;
	height: 42px;
}

.js-authBox__avatar--user:hover {
	background: url(https://staticn.jinse.cn/w/img/a953266.svg) 0 0 no-repeat;
}

.js-userMenu {
	display: none;
	padding: 10px 0;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
	border-radius: 4px;
	border-top: 2px solid;
	-o-border-image: linear-gradient(#ffc462, #f37c00) 10 6 6;
	border-image: linear-gradient(#ffc462, #f37c00) 10 6 6;
	position: absolute;
	top: 65px;
	right: -40px;
	z-index: 11;
}

.js-userMenu__item {
	width: 70px;
	height: 42px;
	line-height: 42px;
	padding: 5px 15px;
	display: block;
	font-size: 14px;
	color: #333;
	text-overflow: ellipsis;
	overflow: hidden;
}

.js-userMenu__item:hover {
	background-color: #fafafa;
}

.js-search-box {
	position: absolute;
	left: 50%;
	margin-left: -225px;
	box-sizing: border-box;
}

.js-search-box__icon {
	display: inline-block;
	width: 14px;
	height: 14px;
	background-size: 14px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1Ljg3NSAxNC44MTRsLjAxNi4wMTYgMy44OSAzLjg5YS43NS43NSAwIDExLTEuMDYgMS4wNmwtMy44OTEtMy44OWEuNzM2LjczNiAwIDAxLS4wMTYtLjAxNSA5LjAwMSA5LjAwMSAwIDExMS4wNi0xLjA2ek05IDE2LjUwM2E3LjUwMSA3LjUwMSAwIDEwMC0xNS4wMDMgNy41MDEgNy41MDEgMCAwMDAgMTUuMDAzeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
}

.js-search-box__input {
	width: 400px;
	height: 34px;
	margin-left: 10px;
	border: none;
	border-bottom: 1px solid #dcdcdc;
	outline: none;
}

.js-search-box__input::-webkit-input-placeholder {
	color: #909099;
}

.to-jinse {
	color: #979797;
	padding: 4px;
	font-size: 15px;
}

.to-jinse:hover {
	color: #ffb100;
}

.fireworks {
	width: 7px;
}

.tag_defi {
	position: absolute;
	top: 21px;
	right: -3px;
}

.tag_defi img {
	width: 12px;
	height: 16px;
}

.tag_new {
	position: absolute;
	top: 14px;
	right: -18px;
}

.tag_new img {
	width: 15px;
	height: 15px;
}

.padding_right {
	padding-right: 12px;
}

.js-nav-i__span__img {
	display: inline-block;
	height: 18px;
	width: 18px;
	text-align: left;
}

.js-nav-i__span__img .js-nav-i__img {
	height: 18px;
	display: inline-block;
	position: relative;
	top: 3px;
	left: 0;
	-o-object-fit: contain;
	object-fit: contain;
}

.js-func-search-box_small {
	width: 222px;
	height: 30px;
	border: 1px solid #dedee1;
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.js-func-search-box_small:hover {
	border: 1px solid #ffb100;
}

.js-func-search-box_small .js-func-search-box__con {
	line-height: 28px;
	height: 28px;
	border-radius: 14px 0 0 14px;
	padding-left: 5px;
	overflow: hidden;
	font-size: 12px;
	padding-right: 3px;
}

.js-func-search-box_small .js-func-search-box__btn {
	float: right;
	width: 69px;
	height: 30px;
	cursor: pointer;
}

.js-func-search-box_small .js-func-search-box__btn img {
	width: 69px;
	height: 30px;
	position: relative;
	top: -1px;
	left: 1px;
}

.js-func-search-box_big {
	width: 456px;
	height: 42px;
	border: 1px solid #dedee1;
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.js-func-search-box_big:hover {
	border: 1px solid #ffb100;
}

.js-func-search-box_big .js-func-search-box__con {
	line-height: 40px;
	height: 40px;
	border-radius: 20px 0 0 20px;
	padding-left: 11px;
	overflow: hidden;
	font-size: 12px;
}

.js-func-search-box_big .js-func-search-box__btn {
	width: 91px;
	height: 42px;
	cursor: pointer;
}

.js-func-search-box_big .js-func-search-box__btn img {
	width: 91px;
	height: 42px;
	position: relative;
	top: -1px;
	left: 1px;
}

.swiper-wrapper {
	height: 40px;
}

.swiper-slide {
	height: 40px;
	overflow: hidden;
	padding-left: 10px;
	display: flex;
	align-items: center;
}

.swiper-slide .swiper-slide-cover {
	line-height: 13px;
}

.swiper-slide .swiper-slide-cover,
.swiper-slide .swiper-slide-cover_big {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	color: #1f1f22;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.swiper-slide .swiper-slide-cover_big {
	line-height: 14px;
	padding-right: 5px;
}

.swiper-height {
	height: 30px;
}

.margin21 {
	margin-right: 21px;
}

.margin34 {
	margin-right: 34px;
}

#swiper_small .fontSize {
	font-size: 12px;
}

#swiper_big .fontSize {
	font-size: 14px;
}

.fadeHeader-enter-active,
.fadeHeader-leave-active {
	transition: all 0.5s;
}

.fadeHeader-enter,
.fadeHeader-leave-to {
	opacity: 0.5;
	transform: translateY(-80px);
}

.fadeNav-enter-active,
.fadeNav-leave-active {
	transition: all 0.5s;
}

.fadeNav-enter,
.fadeNav-leave-to {
	opacity: 0;
}

a {
	transition: all 0.1s;
}

.js-footer--item-content,
.js-footer--item-cooper {
	float: left;
}

.js-partner-spacing {
	height: 277px;
	box-sizing: border-box;
}

.js-footer {
	width: 100%;
	margin: 0 auto;
	background: #1f1f22;
	position: relative;
	z-index: 2;
}

.js-partner {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.js-partner-spacing {
	padding: 36px 0 13px;
}

.js-partner-title {
	font-size: 16px;
	color: #fff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	line-height: 16px;
	height: 16px;
}

.js-partner-column {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	padding-bottom: 30px;
}

.js-partner__item--logo {
	width: 100%;
	max-width: 100%;
	opacity: 0.6;
}

.js-partner__item--logo:hover {
	opacity: 1;
}

.js-partner__item--text {
	font-size: 16px;
	color: #b0b0bb;
}

.js-partner__item--text:hover {
	color: #fff;
}

.js-appDownload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.js-appDownload__qr {
	width: 90px;
	height: 90px;
	margin-top: 13px;
}

.js-appDownload__text {
	width: 90px;
	margin: 10px 0;
	text-align: center;
	font-size: 12px;
	color: #fff;
}

.js-siteRelated {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #393942;
	font-size: 14px;
	color: #b0b0bb;
	height: 56px;
	line-height: 56px;
}

.js-siteRelated__sup {
	display: inline-block;
}

.js-siteRelated__nav {
	display: inline-flex;
}

.js-siteRelated__nav a {
	margin-left: 15px;
	font-size: 14px;
	color: #b0b0bb;
}

.js-siteRelated__nav a:hover {
	color: #fff;
}

.link {
	font-size: 14px;
	color: #a0a0a9;
	margin-right: 5px;
	font-weight: 400;
}

.width450 {
	width: 1000px;
}

.width84 {
	width: 84px;
}

.width40 {
	width: 40px;
	margin-top: 5px;
}

.item_con {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	height: 14px;
	display: inline-block;
	color: #a0a0a9;
	margin-bottom: 21px;
}

.item_con:hover {
	color: #fff;
}

.marginLeft90 {
	margin-left: 90px;
}

.marginLeft130 {
	margin-left: 130px;
}

.con_box {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
}

.link_box {
	margin-top: 21px;
	display: flex;
	justify-content: flex-start;
}

.link_box .js-partner__item_img {
	width: 60px;
	height: 28px;
	display: inline-block;
	margin-bottom: 14px;
}

.link_box .js-partner__item_img img {
	width: 60px;
	height: 28px;
}

.link_box .marginRight30 {
	margin-right: 30px;
}

.js-fotter--con {
	margin-top: 21px;
	display: flex;
}

.fontSize14 {
	font-size: 14px;
}

a {
	transition: all 0.1s;
}

.js-fixed-tools {
	position: fixed;
	right: 30px;
	bottom: 89px;
	width: 50px;
	z-index: 999;
}

.js-fixed-tools ul {
	width: 50px;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	box-sizing: border-box;
	background: #fff;
}

.js-fixed-tools ul li {
	border-bottom: 1px solid #f5f5f5;
	width: 48px;
	height: 50px;
	box-sizing: border-box;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	background-position: 50%;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	transition: all 0.1s;
}

.js-fixed-tools ul li:hover {
	overflow: visible;
	background: #767680;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	background-position: 50%;
	transition: all 0.1s;
}

.js-fixed-tools ul li:hover .left_box,
.js-fixed-tools ul li:hover .left_box_big {
	opacity: 1;
	transition: all 0.1s;
}

.js-fixed-tools ul li .left_box {
	opacity: 0;
	transition: all 0.1s;
	position: relative;
	top: 50%;
	left: -132px;
	transform: translateY(-50%);
	width: 182px;
	height: 50px;
}

.js-fixed-tools ul li .left_box .left_box_con {
	background-image: url(https://staticn.jinse.cn/w/img/2af7a9c.png);
	height: 100%;
	background-size: 126px 50px;
	background-repeat: no-repeat;
	background-position: 0;
}

.js-fixed-tools ul li .left_box .left_box_con .left_box_con_tip {
	width: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: #fff;
}

.js-fixed-tools ul li .left_box_big {
	opacity: 0;
	transition: all 0.1s;
	position: relative;
	top: 50%;
	left: -207px;
	transform: translateY(-50%);
	width: 257px;
	height: 86px;
}

.js-fixed-tools ul li .left_box_big .left_box_con {
	background-image: url(https://staticn.jinse.cn/w/img/57352fb.png);
	height: 100%;
	background-size: 201px 86px;
	background-repeat: no-repeat;
	background-position: 0;
}

.js-fixed-tools ul li .left_box_big .left_box_con .left_box_con_tip {
	display: flex;
}

.js-fixed-tools
	ul
	li
	.left_box_big
	.left_box_con
	.left_box_con_tip
	.left_box_con_tip_left {
	height: 60px;
	width: 60px;
	margin: 13px;
}

.js-fixed-tools
	ul
	li
	.left_box_big
	.left_box_con
	.left_box_con_tip
	.left_box_con_tip_left
	img {
	display: block;
	height: 60px;
	width: 60px;
}

.js-fixed-tools
	ul
	li
	.left_box_big
	.left_box_con
	.left_box_con_tip
	.left_box_con_tip_right {
	height: 60px;
	width: 100px;
	margin: 13px 13px 13px 0;
}

.js-fixed-tools
	ul
	li
	.left_box_big
	.left_box_con
	.left_box_con_tip
	.left_box_con_tip_right
	.left_box_con_tip_right_top {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: #fff;
}

.js-fixed-tools
	ul
	li
	.left_box_big
	.left_box_con
	.left_box_con_tip
	.left_box_con_tip_right
	.left_box_con_tip_right_bottom {
	margin-top: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #fff;
}

.js-fixed-tools ul li:first-child {
	background-image: url(https://staticn.jinse.cn/w/img/7beff51.png);
}

.js-fixed-tools ul li:nth-child(2) {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMiSURBVHgB7ZpNctMwFMefHIZ2RzhBxQkIXICWE5QNlBXRCegNSi8A5QLIWQHDotwgPkJzAsyOXdNhYIAmfjwlTmql/nhKp3Y049+MR5GsD/+tZ0Wj9wSsidYfJSW7AKInhNih3ybfTW9LcEaMAZAuWF6IyYjSSKmXETgiwBEStCtEcAQzUbUR06NGiNNjEhlzGjgJC8PP7yg5hOaIaRb3OOLYwgaDL0fU6ZucLowJxXSdIcJFak4WQdA5T5LpxUqxhLwHWpq1MGbdy6nCEncHGBjzWxVF+WNKQq5prIPWmsRt76emL9NiSXlN6V5ZW9aMkQkOIfNNIYpHSj0/gxq5/gyzWYuK6gdQgdanXbAWCgzrFjUbFRNll4j9svqVwgD+7doD4AAaIDX5aJGnb/FhWX2GsKS3MkAEDUEvdZTJ9crqMoRZq1ftJmiDmfFFN90k5FIpjFagnUzHY2iWmFuRM2NL5v9TTdJZfbGyqCZH2GL/R7MH59AoU7bFOAnbQGTRDc9MkY+TsA1YPNg4CvOHVphvtMJ8oxXmG60w32iF+UYrzDdaYb7RCvONVphvtMJ8oxXmG47CxCafCls4CaOz+3vgCSznugvGZyVEh5zhKF3a0fH5e6VefAU34qIbHGExOEXaiEN6zD44IgT2tNaRUqrsGF0CE44pjt06Xvd8X4wrROXQKaxfOWPkrb8gr+ZicAmVbJ8g/u26fo9Mp720s7/ioopcUyzo+DpKPTNv8bbCkuTVTyydYYYpBpZD3UTpQAMMh0PjD3+SKSp19DOE3Y2yOVrxXkHNkJlCHP94AFZkDozK2lQKS00rygzTr2vWjCCthxCGg/s0Wx/su0FY1pYVS5XGKA5Xmobz/56bhR9pfWrlt7Y6YjKZQBAgXF7+pJ3OVo/GfgtWJByG/f6BKuuXHdan9acTIcTrnC7GaWDJmN7w95u6c8nUd6iPRUifzKnyjVbqp1VRd06BmMXiaoMlyuC0V1Tq4HAehYYR1APOL3Fu4iMRfz++ldDZLHawMywi0WTarSxpivnFxqSThRnH5qJva5QkU1r9/py57kr+A/MRKsY3vdNaAAAAAElFTkSuQmCC);
}

.js-fixed-tools ul li:nth-child(3) {
	background-image: url(https://staticn.jinse.cn/w/img/6bbd048.png);
}

.js-fixed-tools ul li:nth-child(4) {
	background-image: url(https://staticn.jinse.cn/w/img/0ba1d10.png);
}

.js-fixed-tools ul li:nth-child(5) {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGWSURBVHgB7ddBUsIwFIDhPBbOiBs9iRyheAJlx6qNFxFPIDcorNg5nkB6BD2JrnRnfBUzL6kGSJvQDPP+GRha0iTfdMoMQnAcx3Ecx3EcxyUdiMiV5SrDZa4B4LI+Vkq94vuTlNNKRCwarCzLc4CzB0QUjiFzpT7upZTvIkJRYBvUcI0fRzuGviBuHAMXHOaB0kXBBYW5UarCZ2v5syBAju9Z49LguGAwF0qpL3yOpjN77GoGMLhrTBEUFwTmg6Jr4uI6w9qg6Np4uE6wLiiaIw6uNSwEiuYKj2sFC4miOcPivGExUDR3OJwXLCaK1giD2xt2CBSt1R23F+yQKFqzG24nrA8Urd0eB9sn7g9Fe2iHA/eE/aNoL/44+H+idFC0Jxfu86oo8jf812B9AX8nSA+l88EN7AvTRdXVe6j30jg9Ajh9XiyWF+bJgT1mOBeJonRbcI/mCQuGdzI3j1ND6Ry4zDxo3DFR6Q+ponQGTv2+1ub3FkypkxscLPE1Thml2+DEBPd7iz/9E8FxHMdxHMdx3BH2DXTGfasjWDM6AAAAAElFTkSuQmCC);
}

.js-fixed-tools ul li:nth-child(6) {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGiSURBVHgB7djPTQJBFMfx35uDMZ7owBK0BOgADsZ4IlOCFYglWMHAyYMHsAIoATvhhIkHxnmTkAybXXR3Z9hR3ucA7B923jcQEhYQQgghhBBC/A8UbhhjesDlEFAbre8X+AOMee0rpa53u+271nqz36/Ck4iu5kTKEGE+m709IXM8o5t3aS2mPHt4TBXO7e9fWLub5BzHs/GMwa5+eLwYtgo3co0riXLsNNw6CLP2YuSe1of78oorj8La2s/HcAcV32jMvEf0tXQvbw9OJDUZj++e0aHqqO0g/OFgVHaBHOPqRDGqulBOcXWjWGUYyyGuSRQ7Gsa6jGsaxX4MY13EtYnys+GXThnXNsrPhRpOERcjys+EmlLGxYry86CBFHExo/wsaChmXOwoPwdaiBGXIsrPgJbaxKWK8usjgiZxKaP82oikTlzqKL8uIqqKc1YuZOYXJDVG4d8uIkf5dRDZkbgq0aNY9DBWIy5JFFNIQOvRxt1mGFhrX6rO4a9mqiiW5BML8X0/t8zQ3dK74W13q+zDPS60flhBCCGEEEIIIc7XN9bekJaCbjVcAAAAAElFTkSuQmCC);
}

.js-fixed-tools ul li:first-child:hover {
	background-image: url(https://staticn.jinse.cn/w/img/6df32b1.png);
}

.js-fixed-tools ul li:nth-child(2):hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJBSURBVHgB7ZrxUYMwFMYfnv+LExgnkA2kTtIN7AZ2A3WCnhu4AWwgXcDiApYuYHy5hmtKA32vQNPc5Xf3HYGkCV944XrkRXAiUkqBhxSVoO5Q6jzW1QL4VBYtUXkURTmMDRpKUZk8LyvUQk/mKKZepVtWkmguAiLY4Qse5pYqFTIlqkBt9HmTta4zEWDHDOvEUl+iJhieJfRFbsOvyVyOFRq7cWPUVD8pkwyGQB6uqQTOjOUeUuiDnjWTBThARUfjPt662l/BcdLG+Qc4QK+p3Lj00NWeYixpDJCDO5ZGuXM5UIwJo1yAW8zx466XF8XYnVGuwC0ltSHFmMkG3NKcWNHWkGIsNsprcAs5YrjGLg3RVuFbKJLhGnP98iDDNeYNwZhvBGO+EYz5RjDmG8GYbwRjvhGM+UYw5hvBmG8EY74RjPkG19glfxXeg2vsBjzhGgZG71mpDAMBPN5xU/ETeJRtFRRjJfBucoaaAp8EJ0Vl4XR9RhdAhBKKFbPjU7/vV0dMscaiPDFzh0UQ2qvdfPWS4a5Hyqa9aJyXbQ2podjW8QF61mcwDsIodz5hSijubaj3Thw5ERxXHR6NS/02+i8hgUWZQt1zElioHWeDpvvQx611O0rak7QniS2G6Ny4+VqRIWVogvriRg0nrU89+mdLlVrAhT7+QP/tXJVXUqf0CUv9CvU0SFpfjTIn3fItR8wyVfmDmTwPf1q/cpsfSf4TTg5Fi0EBu2TnOhNNNI7Wn7ZcrxOcFaXWUqvg/iv5B1hhhz3wuN55AAAAAElFTkSuQmCC);
}

.js-fixed-tools ul li:nth-child(3):hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKUSURBVHgBzVjhVcIwED6K8B6/ZIQ6gR1BN3ADu4E6ATqBOAFuoBswAjpB6wTwj/eggHd44R0hSZO2gN97faVpLv3ucl8uAeDM2Gw2/aIo7ubzeQznxHK5vEEiY7w24rqDU8NCRF3jCzgBaFqQSNput+/xdyJe5ev1+g3vSRRF961Wqw/HBBFZrVYD9HyqR4Iipfrh8we1Y9snHAO+RBSw74QJPUOT4PwYaSSm+MGRiYhC40ltSdQpeUzRctkuFotE2bhIewEHSasS0ZzZ2vra7MGVH0TQZmP7GEboUTkCTRFxhZojQDaZiRS+H6pxwAciUfeIlCUqAfs8CBsjITXlpZKvk6hMZiDJ2GpVqeTrEuFiORKRnLgKp1HyNAglFxpn2rRMqN03++nDymO60PbdZSslT7/lIFlIojrIZGJNeS6zoagcSJ4M6xDhMW5k0lNUfexMko/w2oUUq22OL3MIACkJ7cY8zgw9ve12u0MfW7SL+efXrtE0ZSRpnx2cr5JsEJLfd4AGogTU8shKLFRJDkJT5xT7EAtVkg3s1KHkQ4mFKskGo+TLYCMWoiQbpOQhFAZiKdSEWm5o+mV75GPc6/VylHIqmmZQEyj5a7pjLs2CCelE8KTQxOmgz4S+oSoh9GrG91i2s/JGrk2aAQkTyqEqTAuZZWF9dS0FUvJ6qQqKEE7Vz9Yoii7VwJhbH3iP6ZlKD7c/uhZLJJIIctVzSE2ZIsCRSJjsE55Mr/D5hbvHnU4no/JiGGcXPXToC6pCSDWTdUxfIGka5DTqJcgm+WAUf8cefYF8N/U1rF2ZypeiqaMznzyk55OyOqbtt8hmIPbRXtsUJ8hzXvbTwK1tZihBKZwL/JfMUO5O4T+Ac8ta3X8BktyPH3FOGb4AAAAASUVORK5CYII=);
}

.js-fixed-tools ul li:nth-child(4):hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALsSURBVHgB7ZrhddowEMfPvHyPN0BMUEagE5QN0kxQOkH9ugDZADpBOkGcTgBdoFY7QKEL9HKH5XDIApxgySiP33v3JEvG1h/dSXqSE9gDIipKPpANyPpkfJ2aagVh0SZdG/tJtuA0SZJloyeQoDFZjvFQkN0cEzXDeJlKLYkQxRUTh17u+qVwh9/QDRwGfZMOYRsWkjm55u3zFYn6aKlfkWVYxtlZwm0juzNtlYzlTYUlagiRwG21xOWyQpJBZBjvkiiXGyqIDOOWkkkPykCsWFPwaYgM02YtihQLeycKmk1254kW+Y2wdE9lbMhpqG8L+wfxshb5tHegsjXMyDszpsAPO22/As8LWiPkXryH16IDCngvf6JB9cA/POoqcc2uPwbPhBDmWtOtwDMhhGlHmfdBKsjg0QUhhK0blrVKCFd8s8I64dJjr8U1EXuenDdcXPFE1nvy3rgIi40uekxDAEIJ+yHyGgJwZV2n4IcMyv2Ua7JvEIAgwsy8NYeAXAaPU6CtgE9mG7og+wIB8C6MhIwouYPSzRVZZsq8wsI0+GXYsKxNtN1jfWgf1+7yI7TPzsDHwrwucWhEfKSED+Oqw8PPjc+NX4bsFM0xcC9OKRYQKbh7bv6de0zueSuIEBLCiYzbTYxJt0hDjFgesM+kc1abWodmM4gI7i2yufPwEuvfdUzgjDFi2BJuq9X2B3njCOvkZDfY4UG7abjLBmS36P7QRvFv5XceGSWHljsa6pN59QmQhLevs5ds2BxwfyXyKWxXL87HkH2l92auF2TYDlNoLmqKp/MXj4UPlifwHIwFnsbROMVycfwa/htjQfzHKPvZyZEXV2db1XB6DYfnupHIsyu+37fKMI0prOIc3EiX/wNlSCw9rWDqYH2EKshSx30K6x6RwTmD9Tkld9yzsO5pHJOdgeWEX1gNn4n6mVX3y9WrZ8keV+PFdu4QpSAmsP5Vmg2PaJ1N/idhxBVvSlQFbufFlbGHEO73BCCAoiCdF+dBAAAAAElFTkSuQmCC);
}

.js-fixed-tools ul li:nth-child(5):hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEKSURBVHgB7djLDcIwEEVRuwOohBYoISWklHRACVACFWA6iGgIMxG2NIvEJMGfGeldaYSE/DsLNhiDEEIIIYQQQqKzpnDe+zN9dDSn8NWL5m6tfRqNEehAc/XLXaY1RlMBNfrfjWpwG1B6cAmUo+nDOFW4BGqYWTuowG1BsT2ycXtQbK9M3D8odoYsXA4UO0sGLieKndkWVwLFzm6DK4lid9TF1UCxu+rgaqLYnWVxLVDs7jK4lij2hrw4CSj2ljw4SSj2piXckWbVAeJQ7G37cJJRsRQutekmGRVbwLnUBvGo2BwutdhpQMUC7h3mkVo4/cZ6//0vUEX01i68WddfeQghhBBCCKGVfQBBn5/P2jvDRAAAAABJRU5ErkJggg==);
}

.js-fixed-tools ul li:nth-child(6):hover {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAELSURBVHgB7djdDcIgFIZh2KCbuEJHcAO7iSu4gY7gBriBcSI8aIknTYsp5eeg35sQE0PhPBdeWKUQQgghhBBCP5i1tqM10NqrRqJZe1oHN3tok7Gfjkp4bkY2rwltnCYWN0G9Cm02LeDmUNQ59ID7jd0l4xZQbubu24NicdEodoA43GYUO0gMLhmKHVgdlxzFDq6Gy4ZiFxTHZUexi4rhiqHYhdlxxVHs4my4aig2QHJcdRQbJBlODIoNtBknDsUGi8aJRflicOJRvjW4ZlC+AM7Y97uUwc7/mZWL8gVwS8lH+Vbg2kH5RtwpgLrkRGmVORq+pw/3nnI3fvWgddVa3xRCCCGEEEII/W9PQ+SZrwZTFU8AAAAASUVORK5CYII=);
}

.js-fixed-tools ul li:last-child {
	border-bottom: none;
	border-radius: 0 0 9px 9px;
}

.js-fixed-tools ul li:first-child {
	border-radius: 10px 10px 0 0;
}

.js-web {
	display: flex;
	flex-direction: column;
}

.js-web .js-content {
	background: #fafafa !important;
	width: 100% !important;
	margin: 0 auto;
}

.js-web {
	display: flex;
	flex-direction: column;
}

.js-web .js-content {
	width: 100% !important;
	margin: 0 auto;
}

.js-web {
	display: flex;
	flex-direction: column;
}

.js-web .js-content {
	width: 100%;
	margin: 0 auto;
}

.js-web {
	width: 100%;
}

.js-web .js-content {
	width: 1170px;
}

.js-web {
	display: flex;
	flex-direction: column;
	width: 100%;
	background: #f7f8fa;
}

.js-web .js-content {
	background: #f7f8fa;
	width: 1170px;
	margin: 0 auto;
}

.js-web {
	display: flex;
	flex-direction: column;
}

.js-web .js-content {
	background-color: #fff;
	width: 1180px;
	margin: 0 auto;
}

.js-message__wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10001;
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-message {
	background: #fff;
	color: #333;
	border-radius: 4px;
	border: 1px solid #ebeef5;
	padding: 15px;
	width: 420px;
}

.js-message__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.js-message__header-title {
	font-size: 18px;
}

.js-message__header-close {
	width: 15px;
	height: 15px;
	cursor: pointer;
}

.js-message__content {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 30px;
}

.js-message__footer {
	display: flex;
	justify-content: flex-end;
}

.js-message-button {
	width: 68px;
	height: 32px;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid #c7c7cd;
	margin-left: 15px;
	cursor: pointer;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
}

.js-message-button:focus {
	outline: none;
}

.js-message-button.close {
	color: #c7c7cd;
}

.js-message-button.primary {
	background: #ffb100;
	border: 1px solid #ffb100;
	color: #fff;
}

.js-message {
	width: 210px;
	height: 28px;
	position: absolute;
	z-index: 100;
	bottom: 500px;
	left: 0;
	padding: 0 3px 0 13px;
	color: #fff;
	font-size: 12px;
	transition: bottom 0.3s;
	display: flex;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-size: 100% 28px;
	background-position-y: center;
}

.js-message .js-message-content {
	display: flex;
	align-items: center;
}

.js-message.showGift {
	background-image: url(https://staticn.jinse.cn/w/img/a42ca60.png);
}

.js-message.showGift .gift-img {
	width: 28px;
	height: 28px;
	margin-top: -13px;
}

.js-message.showGift .person-name {
	max-width: 62px;
	overflow: hidden;
	white-space: nowrap;
}

.js-message.showGift .gift-num,
.js-message.showGift .person-name {
	color: #ffad00;
}

.js-message.showEnter,
.js-message.showQues,
.js-message.showShare {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAN4AAAAcCAYAAAAORwEIAAAB7ElEQVR4Xu2ZvytFYRzGn+codc+R1L3nUG4Gg8FIMZiQxcois0kyKJvNpgySySzTncw3C2UyScqmFIu68iP38opS0rVd7tfXc/+A9zzP53Oe3lOX13chQD8REIHGEiC2spjz3x1KDa+xvHWaCLwTIG5bHlDM51mpR0TD03siAj9FgFjIYm5qeD8FWOeKQD0CAadZG/s1PL0eIvDLBKII44Ucy18fq0/NXxahx/07AqUs4ZSG9++8q3CTCdSqj+jtzvPicw7deE22osf7JxABq4WEKxqef9dqaIvAVRqjh+TTRyzdeLYEKY1TAowwm+a4o+E5FaxaZgkcZglHNDyzfhTMIwESIQQMZgmP3/rpU9OjZXUySYDAdppwTsMzqUehHBO4b62i2NHBG914ji2rmj0CJJbSmOsanj03SuSZAHGe5tCn4XmWrG5WCUxqeFbVKJdbAgT2NDy3elXMKoFAlDU8q3aUyyuByxAwoOF51ate5giQeHqpYayznQcanjk9CuSWALGYxdzQH+huDauYOQIBu1kbZz5y6cYzZ0iBvBEIwEmIMdxF3ml43uyqj1UCFT5jKG3n2eeAuvGs6lIuDwRCREwXYpa+ltHwPOhVB5MESKylMZfrhdPwTCpTqD9PgNg/OcLE6Chr9bq8AqacpmIhnJsdAAAAAElFTkSuQmCC);
	color: #000;
}

.js-message.showEnter img,
.js-message.showQues img,
.js-message.showShare img {
	width: 12px;
}

.js-message.showEnter .name,
.js-message.showQues .name,
.js-message.showShare .name {
	color: #767680;
	margin: 0 5px;
	max-width: 100px;
	overflow: hidden;
	white-space: nowrap;
}

.message-enter-active,
.message-leave-active {
	transition: all 0.3s linear 0s;
}

.message-enter {
	height: 28px;
}

.message-enter,
.message-leave-to {
	transform: translateX(-200px);
	opacity: 0;
	overflow: hidden;
}

.messagebox {
	position: absolute;
}

.js-message {
	background: #eaf5ff;
	width: 210px;
	height: 28px;
	position: fixed;
	z-index: 10010;
	bottom: 500px;
	left: calc(50% - 585px);
	padding-left: 0;
	text-align: center;
	color: #767680;
	font-size: 12px;
	transition: bottom 0.3s;
	box-sizing: border-box;
}

.js-message:after {
	content: "";
	width: 10px;
	height: 14px;
	border-left: 10px solid #eaf5ff;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
	position: absolute;
	right: -10px;
	top: 0;
	box-sizing: border-box;
}

.js-message-wrap {
	width: 100%;
	overflow: hidden;
	line-height: 28px;
	white-space: nowrap;
}

.js-message-wrap img {
	max-width: 14px;
	max-height: 14px;
	vertical-align: sub;
}

.js-message-wrap .name {
	color: #000;
}

.message-enter-active,
.message-leave-active {
	transition: all 0.3s linear 0s;
}

.message-enter,
.message-leave-to {
	opacity: 0;
	width: 0;
	overflow: hidden;
}

.messagebox {
	position: absolute;
}
</style>
