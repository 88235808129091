<template>
	<div id="app" :class="appClass" class="app">
		<leftTab
			class="leftTab"
			v-show="!$route.meta.hideHeader && !isMobileDevice"
		>
		</leftTab>
		<m_header class="m_header" v-show="!$route.meta.hideHeader"></m_header>
		<headern class="headern" v-show="!$route.meta.hideHeader"></headern>
		<div class="boxCon" id="boxCon" style="min-height: 100vh">
			<router-view />
		</div>
		<m_float_download class="m_float_download"> </m_float_download>
		<m_float_language class="m_float_language" v-show="!$route.meta.hideTool">
		</m_float_language>
	</div>
</template>

<script>
import headern from "@/components/pc/headern.vue";
import leftTab from "@/components/pc/leftTab.vue";
import m_header from "@/components/mobile/m_header.vue";
import m_float_download from "@/components/mobile/m_float_download.vue";
import m_float_language from "@/components/mobile/m_float_language.vue";
import footern from "@/components/pc/footern.vue";
import tools from "@/components/tools.vue";
import Vue from "vue";

export const EventBus = new Vue();

export default {
	name: "App",
	components: {
		headern,
		leftTab,
		m_header,
		footern,
		tools,
		m_float_download,
		m_float_language,
	},
	data() {
		return {
			windowWidth: 0, //页面窗口宽度
			windowHeight: 0, //页面窗口高度
			isMobileDevice: false,
			language_en: false,
			appClass: "white-background", // 默认背景色类
		};
	},
	computed: {
		appHeight: function () {
			if (this.windowWidth > 800) {
				return window.innerHeight - 275 - 100 + "px";
			} else {
				return window.innerHeight - 70 - 78 + "px";
			}
		},
	},
	mounted() {
		var that = this;

		//刚进入页面时，获取窗口默认宽高度
		this.windowWidth = window.screen.width;
		this.windowHeight = window.screen.height;

		this.isMobileDevice = this.windowWidth < 800;
		//根据屏幕缩放自动获取页面宽高
		window.onresize = () => {
			return (() => {
				//窗口缩放自动获取页面宽高
				window.fullWidth = window.screen.width;
				window.fullHeight = window.screen.height;
				that.windowWidth = window.fullWidth; //宽
				that.windowHeight = window.fullHeight; //高
				that.isMobileDevice = this.windowWidth < 800;
				// console.log(that.isMobileDevice);
			})();
		};

		// 包含关系
		if (location.href.indexOf("/hk") !== -1) {
			this.appClass = "black-background"; // 如果包含 "/hk"，设置为黑色背景
		} else {
			this.appClass = "white-background"; // 否则，设置为白色背景
		}
	},
	watch: {
		windowHeight(val) {
			let that = this;
		},
		windowWidth(val) {
			let that = this;
			that.isMobileDevice = that.windowWidth < 800;
		},
	},
};
</script>

<style>
html,
body {
	background: #f7f8f9;
	width: 100%;
}

html {
	position: relative;
	width: 100%;
	min-height: 100%;
	min-width: 800px;
}

.m_header {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	/* background: #ffffff; */
	z-index: 999;
}
.leftTab {
	display: contents;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

* {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
}

ol,
ul {
	list-style: none;
}

a {
	cursor: pointer;
}

a {
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
}

img {
	vertical-align: middle;
}

.flex {
	display: flex;
}

.align_items_c {
	align-items: center;
}

.align_items_e {
	align-items: flex-end;
}

.align_items_s {
	align-items: start;
}

.justify_content_c {
	justify-content: center;
}

.justify_content_sb {
	justify-content: space-between;
}

.justify_contentd {
	justify-content: flex-end;
}

.justify_contentd {
	justify-content: flex-end;
}

.flex_wrap {
	flex-wrap: wrap;
}

.direction_column {
	flex-direction: column;
}

.margin_auto {
	margin: 0 auto;
}

.height_100 {
	height: 100%;
}

.ellipsis_one {
	overflow: hidden;
	/* white-space: nowrap; */
	text-overflow: ellipsis;
}

.ellipsis_multiple {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.main {
	position: absolute;
	top: 90px;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
}
.Main,
.app {
	background: #f7f8f9;
}
.white-background {
	background: #f7f8f9;
}

.black-background {
	background-color: black;
}
.main_r {
	width: calc(100% - 210px);
}

.aside .el-menu {
	height: 100%;
}

.card_box .el-card__header {
	background-color: #f9f9f9;
	padding: 10px 15px;
}

.other_main {
	width: 100%;
	top: 70px;
	height: calc(100% - 70px);
}

.card_title {
	font-size: 20px;
	color: #000;
	font-weight: bold;
}

.card_title i {
	margin-right: 10px;
	font-size: 15px;
}

.other_page_r {
	height: 90vh;
	margin-left: 10px;
	margin-top: 10px;
}

.other_page_r .el-card {
	height: 100%;
}

.custom_btn_width_100 {
	width: 100px;
}

.custom_btn .el-button {
	padding: 8px 20px;
}

.custom_input_150 {
	width: 150px !important;
}

.custom_input_160 {
	width: 160px !important;
}

.custom_input_170 {
	width: 170px !important;
}

.custom_input_180 {
	width: 180px !important;
}

.custom_input_190 {
	width: 190px !important;
}

.custom_input_200 {
	width: 200px !important;
}

.custom_input_300 {
	width: 300px !important;
}

.custom_input_350 {
	width: 350px !important;
}

.custom_input_400 {
	width: 400px !important;
}

.table_box {
	margin-top: 20px;
}

.table_footer {
	margin-top: 20px;
}

.pagination-container {
	padding: 0 !important;
}

.pointer {
	cursor: pointer;
}

.mainbackgroundcolor {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	) !important;
}

.maincolor {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.hovercolor:hover {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.margin_auto {
	margin: auto;
}

.margin_left_10 {
	margin-left: 10px;
}

.margin_left_20 {
	margin-left: 20px;
}

.margin_top_20 {
	margin-top: 20px;
}

.margin_top_25 {
	margin-top: 25px;
}

.margin_top_30 {
	margin-top: 30px;
}

.margin_top_40 {
	margin-top: 40px;
}

.margin_top_50 {
	margin-top: 50px;
}

.font_weight_bold {
	font-weight: bold;
}

.hidden_scroll::-webkit-scrollbar {
	display: none;
}

.logo-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.logo-uploader .el-upload:hover {
	border-color: #0056ff;
}

.logo-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px !important;
	text-align: center;
}

.logo {
	width: 100px;
	height: 100px;
	display: block;
}

.addLabelCategoryDialog .el-dialog {
	width: 600px;
	background: #ffffff;
	box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29);
	border-radius: 10px 10px 10px 10px;
	opacity: 1;
}

.width_1200 {
	width: 930px;
}

/* 列表样式一 */
.wzitem {
	width: 100%;
	height: 142px;
	margin-top: 20px;
}

.wzitem_main {
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.wzitem:nth-child(1) {
	margin-top: 0;
}

.wzitem img {
	width: 210px;
	height: 130px;
	border-radius: 10px;
}

.wzitemL {
	width: 70%;
	margin-left: 30px;
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemLTitle div:nth-child(1) {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	line-height: 30px;
}

.wzitemLTitle div:nth-child(2) {
	font-size: 13px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #767680;
	margin-top: 7px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 20px;
}

.lookNum img {
	width: 12px;
	height: 9px;
	margin-right: 5px;
}

.lookNum div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a9;
}

.wzitemLTime_l {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a9;
}

.rsTitle {
	display: flex;
	align-items: center;
}

.rsTitleLeft div:nth-child(1) {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 600 !important;
	line-height: 30px;
	letter-spacing: 0em;
	/* 黑色主色（字段一级） */
	color: #333333;
	width: 260px;
}

.rsTitleLeft_point {
	width: 4px !important;
	height: 4px !important;
	margin-left: 4px;
	opacity: 1;
	border-radius: 2px;
	background: #b2b2b2;
}

/* 进度条 */
.steps .el-step__icon {
	width: 16px;
	height: 16px;
}

.steps .el-step__icon-inner {
}

.steps .el-step__icon.is-text {
	border-radius: 0;
}

.steps .el-step.is-vertical .el-step__line {
	width: 1px;
	top: 10px;
	bottom: 0;
	left: 8px;
}

.steps .el-step__title.is-finish {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.steps .el-step__title.is-process {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.steps .el-step__icon.is-text {
	border-radius: 50%;
	border: 1px solid;
	border-color: #dddfe5;
}

.steps .el-step.is-vertical .el-step__head {
	flex-grow: 0;
	width: 7px;
}

.steps .el-steps--vertical {
	height: 43%;
	flex-flow: column;
}

.el-step__description.is-finish {
	color: #000 !important;
}

/* Meta热搜 */
.rsList {
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	margin-top: 18px;
	padding: 23px 0 25px 21px;
}

.rsItem {
	margin-top: 17px;
}

.rsItem:nth-child(1) {
	margin-top: 0;
}

.rsItem img {
	width: 18px;
	height: 18px;
}

.rsItem div {
	width: 260px;
	margin-left: 0px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

/* 列表样式 二 */
.MainRight_2 {
	background: rgba(255, 255, 255, 0);
	border-radius: 8px 8px 8px 8px;
	opacity: 1;
	/* border: 1px solid #f7f7f7; */
	padding-left: 22px;
}

.ric {
	width: 5px;
	height: 5px;
	background: #000000;
	opacity: 1;
	border-radius: 10px;
	margin-top: 5px;
}

.MainRight_2_item {
	padding-top: 20px;
}

.MainRight_2_item:nth-last-child(1) {
	margin-top: 0;
}

.MainRight_2_item_r {
	width: 300px;
	margin-left: 10px;
	border-bottom: 1px solid #f5f5f5;
}

.MainRight_2_item_r_title {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	line-height: 21px;
}

.MainRight_2_item_r_time {
	margin-top: 13px;
	padding-bottom: 24px;
}

.MainRight_2_item_r_time div {
	font-size: 12px;
	font-family: DINPro-Medium, DINPro;
	color: #a0a0a9;
}

.MainRight_2_item_r_time img {
	width: 15px;
	margin-right: 5px;
}

/* MetaEra 周刊左边 */
.Main_l {
	width: 370px;
	height: 568px;
	border-radius: 20px;
	position: relative;
}

.jszkimg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.Main_l_bot {
	width: 90%;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	bottom: 10px;
}

.Main_l_bot div:nth-child(1) {
	font-size: 28px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.Main_l_bot div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	line-height: 20px;
	margin-top: 5px;
}

.xhBox {
	display: inline-block;
	width: 18px;
	height: 17px;
	text-align: center;
	line-height: 17px;
	font-family: DINOT, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	color: #fff;
	margin-right: 13px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFlSURBVHgB7ZfRTcMwEEDv3PafEboBFKhaf1QKE9ANoCMwAeoErNANmg0IqtqoKihlg4zQ70jxcU4aCFAakEJyH3lSIsdy4if77PgQfsFisXE6nfY1ADnGUBcRTqBE+HsuUXSntQ7xWMPl8uVWKbjnYhf+GZbasVTvoJDvBzwKNCeiM6gU9Fpfq9br4MYY40IFo/JNh0Ph0witVs9jRJxDjbwL2WkiMkHZAftX1EfRPNYtY0mE7GqCGmLmEInQfmmLQPn+xgEho2NRRDgGQShEdQqCUNXvxsdREpZ6HgXCaISKaISKaISKECVkz9WihPivsRUmBK+ihOKYXElC4WjU9yQJTe1NilCo9cXMFmoX4kDeAURX2XMbaqbVoslgoMPsWaWG1bPvl2Uu3Xw9nxhxCxVj+0SMelnc5OEpwwnvkFWl0CFf0+HwfPZTgyS3T/P6+IHNS02J0mmhkLt5QjSu1n2v6J03oLhzk8C7LdcAAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

.xhBox2 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFPSURBVHgB7ZjRTcMwEIb/uzBAR/AGwFsFQioT0A2gI7AACRLvrMAGzQYUgRBvlA0yQhZIjruoUYOKCJXS5B7yPVi2JcufHPuUO8I/kGQ6A0dXIMwgCDo1QZeQpCjKW0o+MsKfImc3YMS6IuDw5CiLU/pdZBpA0VJP5AR9IlhFO3MP59cApyoT0DeEyY8TkuRiDi6XGBDayuhn4ugTXV/YPeFtj58xsEylYU31mkABDuBNG8MJXAU9UIATGMRzOILBdAxHsEbHfqNxC3apB3/qTRjOGIXaGIXaGIXa8CaU+xIirH0JlfLlS0g49SSUUfK2ciQk99Z6Ecro7v3JOh6ELIW+rAfDC5W8sCJDPTShHMOg+4rKvKbNSfuFXaNvbE+rdGzuTZMjSLHQSkdfKXRmr4niXZGaKrev8vqIHyHUdUqU6w6ZRuAXC3oWZ9oWfAOQOVn9W6TQPgAAAABJRU5ErkJggg==);
	background-size: 100% 100%;
}

.xhBox3 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfSURBVHgB7ZhBTsJAFEDffGEnBG7ADZQb4AnkBsoKWbjwBIYTuDBK3HkDewPxBOINuIENuATGP41VBEM1wfYveIu203SSlz//d/rr+AXTa1quzLGHFgsaOGpsE0c081zUe4zdpucmN5yKcKkiDf6feAbNH4VeBzTKjgfvOSRfhnurd97uOJEFEflEZZXatwhNbmk7jQwF8ikUlqnked56wv4RSS/K8Fi0TCARCtXki8mZNRKhUNoYQaYDWlaiE9AKp40hRBwHGEJ0b8r7bbwRsVDqywjG2AllsRPKYieUhTWh2JrQyJSQbvQvpoRkRmRGSL/JxpVzhpYi1A8HE0IhOtUe9+G6cCHtjuM5HKXjEkWzR6feZZwOReMVUwAhMtqldqrdpG3nS0gYkTeO0dzR3P/Im2VK+l+mk1cLHZJXT/3q2bpIStLbJ32940pnbLUlSpZFVMLzpMOo0mOYNecdyMJWzOLN0hcAAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

.xhBox4 {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE1SURBVHgB7ZjbjcIwEEWvnQYogQ52twO2AtgKdilhK0BUQAt0gD/4ByogdOAS3AAJM8ZI4WmQgj0fOZLJJCLR0bVjaaLwBPUCA2gMqRzQ6NPooU0UDPb4Vz+wCo9F/khkEiTejUOFL3VHpE8iCyo/kRKFdXF5rV7ilw4GaVK5pHeWECUzCslkQzVkeJq2aHvBvohuVCtkljlqILxNedbMFTr8TiAE7Tc9IekwnNAIgtAo8AFBaNSJd+MIPGXZX/UmGsLohGJ0QjE6oRjShJwsoRqlNKGdtCkzkoQsNYprOUIVpnyQIsTpzLmQIMQt9PfpJL9QhTF/ZDidspBDHlyQMc2LmnrXEukp/ZeOsG5wJrTHGOlSsj6VoZext/7ge3vf1xeY0U7Zdkvk6JmWxoZqw/tM7IYDdfdFor4RiC0AAAAASUVORK5CYII=);
	background-size: 100% 100%;
}

.m_header {
	display: none;
}
.headern {
	margin-left: 230px;
}
.headern,
.lef-tools {
	display: block;
}
.boxCon {
	background: #f7f8f9;
	margin-top: 0;
	margin-left: 230px;
}
.m_float_download,
.m_float_language {
	display: none;
}
@media screen and (max-width: 950px) {
	.m_float_download,
	.m_float_language {
		display: block;
	}
	.Main,
	.app {
		background-color: white;
	}
	.white-background {
		background-color: white;
	}

	.black-background {
		background-color: black;
	}
	html {
		position: relative;
		width: 100%;
		min-height: 100%;
		min-width: 100%;
	}
	.width_1200 {
		width: 100%;
	}

	.m_header {
		display: block;
	}
	.leftTab {
		display: none;
	}
	.headern,
	.lef-tools {
		display: none;
	}
	.boxCon {
		margin-top: 94px;
		margin-left: 0px;
	}
	.margin_left_20_media {
		margin-left: 20px;
	}

	.margin_right_20_media {
		margin-right: 20px;
	}
	.wzitem img {
		width: 105px !important;
		height: 65px !important;
		border-radius: 10px;
	}
	.wzitem {
		width: 100%;
		height: 90px;
		margin-top: 10px;
	}
	.wzitemL {
		width: 68%;
		margin-left: 0;
		padding-left: 20px;
		height: 90%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.padding_bot_0 {
	padding-bottom: 0 !important;
}

.margin_top_0 {
	margin-top: 0 !important;
}

.el-step__main {
	width: 97% !important;
}

@import url(./assets/pub3.css);

@import url(./assets/zl.css);
@import url(./assets/zl2.css);
@import url(./assets/zk.css);
@import url(./assets/article.css);
@import url(./assets/hk.css);
</style>
