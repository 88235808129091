<template>
	<div class="headerg flex align_items_c">
		<div class="header_search align_items_c flex pointer">
			<img
				src="https://zy.metaera.media/assets/images/tab/tab_search.png"
				alt=""
			/>
			<input
				type="text"
				@keydown.enter="handleEnterKey"
				v-model="keyword"
				placeholder="Search for the content you want to view…"
			/>
			<div
				class="searchBtn flex align_items_c justify_content_c"
				@click="
					$router.push({
						path: '/search',
						query: { keyword: keyword },
					})
				"
			>
			</div>
		</div>
		<div class="js-func js-func__app header_phone_img pointer">
			<div class="js-func__item--app_small tab_phone_img"></div>
			<div class="js-func__download">
				<div class="qr">
					<img
						src="https://zy.metaera.media/assets/images/aboutus/download.png"
						alt="MetaEra中国版App下载"
					/>
					<span class="js-func__download_des">Android &amp; iPhone</span>
				</div>
			</div>
		</div>
		<img
			class="tab_noLogin pointer"
			v-if="token == 0 || token == undefined"
			:src="currentIcon"
			alt=""
			@click="login"
			@mouseover="hoverIcon"
			@mouseout="defaultIcon"
		/>
		<div
			class="js-func js-func__user js-userBox margin0 header_mine_img"
			v-else
		>
			<a title="" class="js-func__avatar js-func__avatar_small">
				<img
					class="tab_mine_img"
					v-if="!(token == 0 || token == undefined) && $store.state.user.avatar"
					:src="$store.state.user.avatar"
					alt=""
					@click="login"
				/>
				<img
					class="tab_mine_img"
					v-else
					src="https://zy.metaera.media/assets/images/tab/tab_mine.png"
					alt=""
					@click="login"
				/>
			</a>
			<a class="js-func__userMenu">
				<div class="tab_user_header flex">
					<div
						class="tab_user_header_img"
						@click="$router.push({ path: '/my' })"
					>
						<img
							class="tab_user_img"
							v-if="
								!(token == 0 || token == undefined) && $store.state.user.avatar
							"
							:src="$store.state.user.avatar"
							alt=""
							@click="login"
						/>
						<img
							class="tab_user_img"
							v-else
							src="https://zy.metaera.media/assets/images/tab/tab_mine.png"
							alt=""
							@click="login"
						/>
					</div>
					<div class="tab_user_header_left">
						<div class="tab_user_header_phone">
							{{ this.mobile }}
						</div>
						<div class="tab_user_header_name">
							{{ $store.state.user.name }}
						</div>
					</div>
				</div>
				<div class="tab_user_publish" @click="gotoMy"> </div>
				<div
					class="tab_user_menu flex"
					style="margin-top: 10px"
					@click="$router.push({ path: '/my' })"
				>
					<div class="tab_user_menu_img">
						<img
							src="https://zy.metaera.media/assets/images/my/my_user_info.png"
							alt=""
						/>
					</div>
					<div class="tab_user_menu_con"> 用户信息 </div>
				</div>
				<div class="tab_user_menu_line flex"> </div>
				<div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/mywz' })"
				>
					<div class="tab_user_menu_img">
						<img
							src="https://zy.metaera.media/assets/images/my/my_user_attention.png"
							alt=""
						/>
					</div>
					<div class="tab_user_menu_con"> 我的文章 </div>
				</div>
				<div class="tab_user_menu_line flex"> </div>
				<div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/ac_mine' })"
				>
					<div class="tab_user_menu_img">
						<img
							src="https://zy.metaera.media/assets/images/my/my_user_event.png"
							alt=""
						/>
					</div>
					<div class="tab_user_menu_con">活动参与 </div>
				</div>

				<div class="tab_user_menu_line flex"> </div>
				<div
					class="tab_user_menu flex"
					@click="$router.push({ path: '/ac_organ' })"
				>
					<div class="tab_user_menu_img">
						<img
							src="https://zy.metaera.media/assets/images/my/my_user_organ.png"
							alt=""
						/>
					</div>
					<div class="tab_user_menu_con">活动主办 </div>
				</div>
				<div class="tab_user_menu_line flex"> </div>
				<div class="tab_user_logout flex" @click="logOut">退出登录</div>
			</a>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken, getUserMobile } from "@/utils/auth";
import { getMap } from "echarts";
export default {
	name: "",
	created() {
		this.token = getToken();
		this.mobile = getUserMobile();
		this.$router.beforeEach((to, from, next) => {
			this.keyword = "";
			next();
		});
	},
	data() {
		return {
			token: 0,
			mobile: "",
			keyword: "",
			defaultIconSrc:
				"https://zy.metaera.media/assets/images/tab/tab_mine_1.png",
			hoverIconSrc:
				"https://zy.metaera.media/assets/images/tab/tab_mine_hover_2.png",
			currentIcon: "https://zy.metaera.media/assets/images/tab/tab_mine_1.png",
		};
	},
	methods: {
		hoverIcon() {
			this.currentIcon = this.hoverIconSrc;
		},
		defaultIcon() {
			this.currentIcon = this.defaultIconSrc;
		},
		gotoMy() {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				this.$router.push({ path: "/publish" });
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		handleEnterKey() {
			this.$router.push({
				path: "/search",
				query: { keyword: this.keyword == null ? "" : this.keyword },
			});
		},
		login() {
			if (this.token == 0 || this.token == undefined) {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			} else {
				this.$router.push({ path: "/my" });
			}
		},

		logOut() {
			store.dispatch("user/logout");
		},
	},
	watch: {
		"$route.path": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
.headerg {
	background: #f7f8f9;
	height: 111px;
}

.header_search {
	position: absolute;
	left: 270px;
	right: 232px;
	top: 31px;
	height: 50px;
	opacity: 1;
	border-radius: 17px;
	box-shadow: 5px 15px 70px 0px rgba(54, 62, 147, 0.1);
}

.header_search img {
	width: 22px;
	height: 22px;
	margin-left: 22px;
}

.header_search input {
	margin-left: 10px;
	margin-right: 20px;
	width: 100%;
	height: 50px;
	border: none;
	background: #f7f8f9;
}

.tab_phone_img {
	width: 40px;
	height: 40px;
	border-radius: 10px;
}

.tab_mine_img,
.tab_noLogin {
	margin-top: 5px;
	width: 30px;
	height: 30px;
	border-radius: 10px;
}

.header_phone_img {
	position: absolute;
	right: 80px;
	top: 26px;
}
.header_mine_img {
	position: absolute;
	right: 40px;
	top: 21px;
}
.tab_noLogin {
	position: absolute;
	right: 40px;
	top: 36px;
}
.tab_user_header_img {
	margin-left: 7px;
	margin-top: 7px;
}
.tab_user_img {
	width: 40px;
	height: 40px;
	border-radius: 12px;
}
.tab_user_header_left {
	margin-left: 10px;
	margin-top: 7px;
	height: 40px;
}

.tab_user_header_phone {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	letter-spacing: 0em;
	margin-top: 4px;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.tab_user_header_name {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	margin-top: 6px;
	/* 描述字段 */
	color: #656878;
}

.tab_user_publish {
	background-image: url("https://zy.metaera.media/assets/images/zl/zl_publish.png");
	background-size: cover;
	margin-top: 20px;
	width: 200px;
	height: 48px;
	margin-left: 7px;
}

.tab_user_menu {
	height: 38px;
}
.tab_user_menu_img {
	margin-left: 10px;
	margin-top: 10px;
}

.tab_user_menu_img img {
	width: 14px;
	height: 14px;
}

.tab_user_menu_con {
	margin-top: 18px;
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.tab_user_menu_line {
	margin-left: 10px;
	width: 200px;
	height: 1px;
	opacity: 0.4;
	background: #ededed;
}

.tab_user_logout {
	margin-left: 10px;
	margin-top: 20px;
	width: 200px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	justify-content: center;
	align-items: center;
	/* 主色（一级颜色） */
	color: #0056ff;
}
</style>
