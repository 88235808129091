<template>
	<div class="flex direction_column align_items_c">
		<div class="loginTab flex">
			<div class="active">登录</div>
		</div>

		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone">
					<span
						class="js-login__areaCode pointer"
						@click="showtimezone = !showtimezone"
					>
						+{{ loginForm.phone_code }}
						<i class="js-login__areaIcon"></i>
					</span>
					<login__areaCode v-if="showtimezone" @phone_code="getphone_code">
					</login__areaCode>

					<input
						type="text"
						placeholder="请输入手机号码"
						v-model="loginForm.username"
					/>
				</p>
				<p class="js-login_title"> 密码 </p>
				<p class="js-login__psd">
					<input
						placeholder="请输入8-24位密码"
						:type="passType"
						v-model="loginForm.password"
					/>
					<img
						src="https://staticn.jinse.cn/w/img/b319774.svg"
						@click="
							passType == 'password'
								? (passType = 'text')
								: (passType = 'password')
						"
						class="pointer"
					/>
				</p>
				<p class="js-login__info">
					<span
						class="js-login__changePhone pointer"
						@click="$parent.$parent.showStatus = 3"
					>
						手机验证码登录</span
					>
					<span
						class="js-login__forget pointer"
						@click="$parent.$parent.showStatus = 4"
						>忘记密码？</span
					>
				</p>
				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					@click="handleLogin"
					:disabled="loginForm.username == '' || loginForm.password == ''"
				>
					登 录
				</button>
				<div class="js-login_reigister">
					没有账号？<i @click="$parent.$parent.showStatus = 2">去注册</i>
				</div>
			</div>
			<!---->
			<div class="js-thirdParty" style="display: none">
				<div class="js-thirdParty__head" style="font-size: 13px"
					>第三方登录</div
				>
				<a
					href="https://open.weixin.qq.com/connect/qrconnect?appid=wx5805a425ba4944d1&amp;redirect_uri=https%3A%2F%2Fwww.jinse.cn%2Fuser%2Foauth&amp;response_type=code&amp;scope=snsapi_login&amp;state=6a47b36bbd40846f-1691494362#wechat_redirect"
					title="微信登录"
					target="blank"
					class="js-login__weixinlogo"
				></a>
			</div>
			<div id="login_container" style="display: none">
				<iframe
					src="https://open.weixin.qq.com/connect/qrconnect?appid=wx5805a425ba4944d1&amp;scope=snsapi_login&amp;redirect_uri=https%3A%2F%2Fwww.jinse.cn%2Fuser%2Foauth&amp;state=9e24c59d4af888df-1542076566&amp;login_type=jssdk&amp;self_redirect=default&amp;styletype=&amp;sizetype=&amp;bgcolor=&amp;rst=&amp;style=black"
					frameborder="0"
					scrolling="no"
					width="300px"
					height="400px"
				></iframe>
			</div>
			<div id="captcha">
				<input
					type="hidden"
					name="NECaptchaValidate"
					value=""
					class="yidun_input"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import login__areaCode from "./login__areaCode.vue";
import wxlogin from "vue-wxlogin";
export default {
	name: "",
	components: { login__areaCode },
	data() {
		return {
			loginForm: {
				username: "",
				password: "",
				type: "1",
				phone_code: "86",
			},
			passType: "password",

			showtimezone: false,
		};
	},
	methods: {
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		handleLogin() {
			store
				.dispatch("user/login", this.loginForm)
				.then(() => {
					this.$parent.$parent.dialogVisible = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
/* 登录注册 */
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}

.loginMain {
	margin-top: 50px;
	width: 344px;
}

.login_form {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #e1e1e1;
}

.numberarea {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	width: 78px;
}

.login_form input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.login_form img {
	width: 14px;
}

.login_bt {
	margin-top: 15px;
}

.login_bt div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.loginBtn {
	width: 335px;
	height: 50px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	margin-left: 10px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

.otherLogin {
	margin-top: 37px;
}

.otherLogin .el-divider__text {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wxlogin {
	width: 40px;
	margin-top: 28px;
}

@import url("../../assets/log.css");
</style>
