<template>
	<ul class="timezone-list">
		<li
			class="timezone-single pointer"
			v-for="(item, index) in PhoneCode"
			:key="index"
			@click="chooseArea(item)"
		>
			{{ item.country }}
			<i>+{{ item.area_code }}</i>
		</li>
	</ul>
</template>

<script>
import { getPhoneCode } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			PhoneCode: [],
		};
	},
	created() {
		this.getPhoneCode();
	},
	methods: {
		getPhoneCode() {
			getPhoneCode({})
				.then((res) => {
					if (res.code == 200) {
						this.PhoneCode = res.data;
					} else {
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		chooseArea(item) {
			this.$emit("phone_code", item.area_code);
		},
	},
};
</script>

<style scoped>
@import url("../../assets/log.css");
</style>
