import Pagination from "./Pagination";
import resou from "./resou";
import event from "./event";
import steps from "./steps";
import othresou from "./pc/24resou";
import PaginationPart from "./paginationPart";
import CropperImageNew from "./pc/CropperImageNew.vue";

import m_othresou from "./mobile/m_24resou";
import more from "./more";
import noData from "./noData";
import share from "./share";
import scrollToBottom from "./scrollToBottom";
export default {
	install(Vue) {
		Vue.component("Pagination", Pagination);
		Vue.component("PaginationPart", PaginationPart);
		Vue.component("resou", resou);
		Vue.component("event", event);
		Vue.component("steps", steps);
		Vue.component("othresou", othresou);

		Vue.component("m_othresou", m_othresou);
		Vue.component("more", more);
		Vue.component("noData", noData);
		Vue.component("scrollToBottom", scrollToBottom);
		Vue.component("CropperImageNew", CropperImageNew);
	},
};
