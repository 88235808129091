import Cookies from "js-cookie";

const TokenKey = "wwy-treeadmin-users";
const UserId = "wwy-vecoadmin-userid";
const UserMobile = "wwy-vecoadmin-usermobile";

export function getToken() {
	return Cookies.get(TokenKey);
}

export function setToken(token) {
	return Cookies.set(TokenKey, token);
}

export function removeToken() {
	return Cookies.remove(TokenKey);
}

export function getUserToken() {
	return Cookies.get(TokenKey);
}

export function getUserId() {
	return Cookies.get(UserId);
}

export function setUserId(userId) {
	return Cookies.set(UserId, userId);
}

export function removeUserId() {
	return Cookies.remove(UserId);
}
export function getUserMobile() {
	return Cookies.get(UserMobile);
}

export function setUserMobile(userMobile) {
	return Cookies.set(UserMobile, userMobile);
}

export function removeUserMobile() {
	return Cookies.remove(UserMobile);
}
