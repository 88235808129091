<template>
	<div style="position: relative">
		<div class="flex align_items_c btnitem">
			<img src="https://zy.metaera.media/assets/images/home/685.png" alt="" />
			<div style="font-size: 12px; margin-left: 4px; font-weight: 400"
				>分享</div
			>
		</div>
		<img :src="tupian" alt="" class="wemImg" />
	</div>
</template>

<script>
import AraleQRCode from "arale-qrcode";
export default {
	props: ["url"],
	name: "",
	data() {
		return {
			tupian: "",
		};
	},

	methods: {
		makeCode() {
			const result = new AraleQRCode({
				render: "svg" /*  生成的类型 'svg' or 'table dom元素类型 */,
				text: this.url /* 二维码的链接*/,
				size: 100,
			});
			//   console.log('====',result)
			const svgXml = new XMLSerializer().serializeToString(result);
			//   console.log('----',svgXml)
			const src =
				"data:image/svg+xml;base64," +
				window.btoa(unescape(encodeURIComponent(svgXml)));
			//   console.log('*****',src)
			localStorage.setItem("image", src);
			this.jieshou();
		},
		jieshou() {
			this.tupian = localStorage.getItem("image");
			localStorage.removeItem("image");
		},
	},
	mounted() {
		this.makeCode();
	},
};
</script>

<style scoped>
.btnitem:hover .wemImg {
	display: block;
}
.wemImg {
	display: none;
	position: absolute;
	z-index: 100;
	left: 50%;
	transform: translateX(-50%);
	top: 32px;
}
</style>
