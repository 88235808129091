var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eventWraper"},[(
			_vm.EventDetails.length > 0 ||
			_vm.recommendLists.length > 0 ||
			_vm.labelDetails.length > 0 ||
			_vm.kxunTuiJianDetails.length > 0
		)?_c('div',{staticClass:"rsTitle rsTitleBg flex align_items_c justify_content_sb"},[_c('div',{staticClass:"rsTitleLeft flex align_items_c"},[_c('div',{staticClass:"leftText pointer hovercolor",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"flex align_items_c pointer justify_content_sb",on:{"click":_vm.toMore}},[_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"})])])]):_vm._e(),(
			_vm.EventDetails.length > 0 ||
			_vm.recommendLists.length > 0 ||
			_vm.labelDetails.length > 0 ||
			_vm.kxunTuiJianDetails.length > 0
		)?_c('div',{staticClass:"MainRight_2 eventWraper_content"},[(_vm.type == 'event')?_c('div',_vm._l((_vm.EventDetails),function(item,eventIndex){return _c('div',{key:eventIndex,staticClass:"MainRight_2_item flex align_items_s moduleBox",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"ric"}),_c('div',{staticClass:"MainRight_2_item_r"},[_c('div',{staticClass:"MainRight_2_item_r_title pointer hoverTitle",on:{"click":function($event){return _vm.$router.push({
								path: '/kuaixun_wz',
								query: { id: item.id },
							})}}},[_vm._v(_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title))]),_c('div',{staticClass:"flex align_items_c MainRight_2_item_r_time"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/home/2.png","alt":""}}),_c('div',[_vm._v(_vm._s(item.release_time))])])])])}),0):_vm._e(),(_vm.type == 'recommend')?_c('div',{staticStyle:{"padding-bottom":"20px"}},_vm._l((_vm.recommendLists),function(item,recommendIndex){return _c('div',{key:recommendIndex,staticClass:"MainRight_2_item flex align_items_s moduleBox"},[_c('div',{staticClass:"reItem_Left",class:{
						reItem_Left_full: !(item.pic != null && item.pic.length > 0),
					}},[_c('div',{staticClass:"reItem_Left_title pointer hovercolor"},[_vm._v(_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title))]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"reItem_Left_line"}),_c('div',{staticClass:"reItem_Left_time"},[_vm._v(_vm._s(item.release_time))])])]),_c('div',{staticClass:"reItem_Right",staticStyle:{"display":"(item.pic != null && item.pic.length > 0):contents:none"}},[_c('img',{key:item.pic,staticClass:"rsItemImg",attrs:{"src":item.pic,"alt":""}})])])}),0):_vm._e(),(_vm.type == 'getLabelDetails')?_c('div',_vm._l((_vm.labelDetails),function(item,labelIndex){return _c('div',{key:labelIndex,staticClass:"MainRight_2_item flex align_items_s"},[_c('div',{staticClass:"ric"}),_c('div',{staticClass:"MainRight_2_item_r"},[_c('div',{staticClass:"MainRight_2_item_r_title pointer hovercolor",on:{"click":function($event){return _vm.$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})}}},[_vm._v(_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title))]),_c('div',{staticClass:"flex align_items_c MainRight_2_item_r_time"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/home/2.png","alt":""}}),_c('div',[_vm._v(_vm._s(item.release_time))])])])])}),0):_vm._e(),(_vm.type == 'kxtuijian')?_c('div',_vm._l((_vm.kxunTuiJianDetails),function(item,kxunIndex){return _c('div',{key:kxunIndex,staticClass:"MainRight_2_item flex align_items_s"},[_c('div',{staticClass:"ric"}),_c('div',{staticClass:"MainRight_2_item_r"},[_c('div',{staticClass:"MainRight_2_item_r_title pointer hovercolor",on:{"click":function($event){return _vm.$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})}}},[_vm._v(_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title))]),_c('div',{staticClass:"flex align_items_c MainRight_2_item_r_time"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/home/2.png","alt":""}}),_c('div',[_vm._v(_vm._s(item.release_time))])])])])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }