<template>
	<div class="paginationBox">
		<span
			class="tagIcon leftDark"
			:class="{ leftLight: currentPage > 1 }"
			@click="changePage(-1)"
		></span>
		<span class="pageNum">{{ currentPage }}/{{ sumPage }}</span>
		<span
			class="tagIcon rightDark"
			:class="{ rightLight: currentPage < sumPage }"
			@click="changePage(1)"
		></span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentPage: 1,
			sumPage: 2,
		};
	},
	methods: {
		changePage(page) {
			const toPage = this.currentPage + page;
			if (toPage < 1 || toPage > this.sumPage) {
				return;
			}
			this.currentPage = toPage;
			this.$emit("toNewPage", toPage);
		},
	},
};
</script>

<style scoped>
.paginationBox {
	display: flex;
	align-items: center;
}
.pageNum {
	margin: 0 20px;
	line-height: 15px;
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	color: #757781;
}

.tagIcon {
	width: 12px;
	height: 12px;
}
.leftDark {
	/* transform: rotate(180deg); */
	background: url("https://zy.metaera.media/assets/images/home/darkIcon_1.png")
		0 0/100% no-repeat;
}
.leftLight {
	transform: rotate(180deg);
	background: url("https://zy.metaera.media/assets/images/home/lightIcon_1.png")
		0 0/100% no-repeat;
}
.rightDark {
	transform: rotate(180deg);
	background: url("https://zy.metaera.media/assets/images/home/darkIcon_1.png")
		0 0/100% no-repeat;
}
.rightLight {
	background: url("https://zy.metaera.media/assets/images/home/lightIcon_1.png")
		0 0/100% no-repeat;
	transform: rotate(0deg);
}
</style>
