<template>
	<div>
		<div text="没有更多数据了" class="js-loading">
			<p></p>
			<div class="js-loadMoreBtn pointer" @click="more"
				><span> 加载更多信息 </span>
				<!----></div
			>
			<p></p>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	methods: {
		more() {
			this.$emit("infinite");
		},
	},
};
</script>

<style scoped>
.js-loading {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.js-loading .js-loadMoreBtn {
	width: 98px;
	height: 28px;
	line-height: 28px;
	box-sizing: border-box;
	border-radius: 14px;
	text-align: center;
	cursor: pointer;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: #1f1f22;
	width: 114px;
	background: #f9f9f9;
	border: 0;
	margin: 0 13px;
	transition: all 0.1;
}
.js-loadMoreBtn span {
	font-size: 14px;
}

.js-loading .js-loadMoreBtn:hover {
	background: #f5f5f5;
}

.js-loading .js-no-more {
	width: 110px;
	height: 28px;
	line-height: 28px;
	margin: 0 21px;
	background: #fff;
	border: 1px solid #f9f9f9;
	box-sizing: border-box;
	border-radius: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	color: #dedee1;
}

.js-loading > p {
	flex: 1;
	border-bottom: 1px solid #f9f9f9;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
</style>
