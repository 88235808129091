var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"js-fixed-tools"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.$router.push({ path: '/aboutus' })}}},[_vm._m(0)]),_vm._m(1),_c('li',{staticClass:"pointer",on:{"click":function($event){return _vm.$router.push({ path: '/home_shequn' })}}},[_vm._m(2)]),_c('li',{staticClass:"pointer",on:{"click":function($event){return _vm.$router.push({ path: '/feed_back' })}}},[_vm._m(3)]),_c('li',{staticClass:"pointer",on:{"click":_vm.toTop}},[_vm._m(4)]),_c('li',{on:{"click":_vm.toBot}},[_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_vm._v("寻求报道")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"left_box_big"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_c('div',{staticClass:"left_box_con_tip_left"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/aboutus/download.png","alt":""}})]),_c('div',{staticClass:"left_box_con_tip_right"},[_c('div',{staticClass:"left_box_con_tip_right_top"},[_vm._v("MetaEra APP")]),_c('div',{staticClass:"left_box_con_tip_right_bottom"},[_vm._v(" iOS & Android ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_vm._v("加入社群"),_c('br'),_vm._v(" Telegram")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_vm._v("意见反馈")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_vm._v("返回顶部")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_box"},[_c('div',{staticClass:"left_box_con"},[_c('div',{staticClass:"left_box_con_tip"},[_vm._v("返回底部")])])])
}]

export { render, staticRenderFns }