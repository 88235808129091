var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headerg flex align_items_c"},[_c('div',{staticClass:"header_search align_items_c flex pointer"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/tab/tab_search.png","alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],attrs:{"type":"text","placeholder":"Search for the content you want to view…"},domProps:{"value":(_vm.keyword)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnterKey.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.keyword=$event.target.value}}}),_c('div',{staticClass:"searchBtn flex align_items_c justify_content_c",on:{"click":function($event){return _vm.$router.push({
					path: '/search',
					query: { keyword: _vm.keyword },
				})}}})]),_vm._m(0),(_vm.token == 0 || _vm.token == undefined)?_c('img',{staticClass:"tab_noLogin pointer",attrs:{"src":_vm.currentIcon,"alt":""},on:{"click":_vm.login,"mouseover":_vm.hoverIcon,"mouseout":_vm.defaultIcon}}):_c('div',{staticClass:"js-func js-func__user js-userBox margin0 header_mine_img"},[_c('a',{staticClass:"js-func__avatar js-func__avatar_small",attrs:{"title":""}},[(!(_vm.token == 0 || _vm.token == undefined) && _vm.$store.state.user.avatar)?_c('img',{staticClass:"tab_mine_img",attrs:{"src":_vm.$store.state.user.avatar,"alt":""},on:{"click":_vm.login}}):_c('img',{staticClass:"tab_mine_img",attrs:{"src":"https://zy.metaera.media/assets/images/tab/tab_mine.png","alt":""},on:{"click":_vm.login}})]),_c('a',{staticClass:"js-func__userMenu"},[_c('div',{staticClass:"tab_user_header flex"},[_c('div',{staticClass:"tab_user_header_img",on:{"click":function($event){return _vm.$router.push({ path: '/my' })}}},[(
							!(_vm.token == 0 || _vm.token == undefined) && _vm.$store.state.user.avatar
						)?_c('img',{staticClass:"tab_user_img",attrs:{"src":_vm.$store.state.user.avatar,"alt":""},on:{"click":_vm.login}}):_c('img',{staticClass:"tab_user_img",attrs:{"src":"https://zy.metaera.media/assets/images/tab/tab_mine.png","alt":""},on:{"click":_vm.login}})]),_c('div',{staticClass:"tab_user_header_left"},[_c('div',{staticClass:"tab_user_header_phone"},[_vm._v(" "+_vm._s(this.mobile)+" ")]),_c('div',{staticClass:"tab_user_header_name"},[_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" ")])])]),_c('div',{staticClass:"tab_user_publish",on:{"click":_vm.gotoMy}}),_c('div',{staticClass:"tab_user_menu flex",staticStyle:{"margin-top":"10px"},on:{"click":function($event){return _vm.$router.push({ path: '/my' })}}},[_vm._m(1),_c('div',{staticClass:"tab_user_menu_con"},[_vm._v(" 用户信息 ")])]),_c('div',{staticClass:"tab_user_menu_line flex"}),_c('div',{staticClass:"tab_user_menu flex",on:{"click":function($event){return _vm.$router.push({ path: '/mywz' })}}},[_vm._m(2),_c('div',{staticClass:"tab_user_menu_con"},[_vm._v(" 我的文章 ")])]),_c('div',{staticClass:"tab_user_menu_line flex"}),_c('div',{staticClass:"tab_user_menu flex",on:{"click":function($event){return _vm.$router.push({ path: '/ac_mine' })}}},[_vm._m(3),_c('div',{staticClass:"tab_user_menu_con"},[_vm._v("活动参与 ")])]),_c('div',{staticClass:"tab_user_menu_line flex"}),_c('div',{staticClass:"tab_user_menu flex",on:{"click":function($event){return _vm.$router.push({ path: '/ac_organ' })}}},[_vm._m(4),_c('div',{staticClass:"tab_user_menu_con"},[_vm._v("活动主办 ")])]),_c('div',{staticClass:"tab_user_menu_line flex"}),_c('div',{staticClass:"tab_user_logout flex",on:{"click":_vm.logOut}},[_vm._v("退出登录")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"js-func js-func__app header_phone_img pointer"},[_c('div',{staticClass:"js-func__item--app_small tab_phone_img"}),_c('div',{staticClass:"js-func__download"},[_c('div',{staticClass:"qr"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/aboutus/download.png","alt":"MetaEra中国版App下载"}}),_c('span',{staticClass:"js-func__download_des"},[_vm._v("Android & iPhone")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_user_menu_img"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/my/my_user_info.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_user_menu_img"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/my/my_user_attention.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_user_menu_img"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/my/my_user_event.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_user_menu_img"},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/my/my_user_organ.png","alt":""}})])
}]

export { render, staticRenderFns }