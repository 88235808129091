// 封装自定义指令
export default {
	bind(el, binding) {
		const { value } = binding;
		var time = Date.parse(value.replace(/-/gi, "/"));
		if (isNaN(time)) {
			return;
		}
		var minute = 1000 * 60;
		var hour = minute * 60;
		var day = hour * 24;
		var halfamonth = day * 15;
		var month = day * 30;
		// var now = new Date().getTime();
		const now = new Date();
		const localDate = new Date(now);
		const utcDate = new Date(
			localDate.getUTCFullYear(),
			localDate.getUTCMonth(),
			localDate.getUTCDate(),
			localDate.getUTCHours(),
			localDate.getUTCMinutes(),
			localDate.getUTCSeconds()
		);

		const targetOffsetMs = 8 * 60 * 60 * 1000;
		const targetTimeMs = utcDate.getTime() + targetOffsetMs;
		var diffValue = targetTimeMs - time;

		if (diffValue < 0) {
			const formattedTime = new Date(time).toLocaleString();
			el.innerHTML = formattedTime;
			return;
		}
		var monthC = diffValue / month;
		var weekC = diffValue / (7 * day);
		var dayC = diffValue / day;
		var hourC = diffValue / hour;
		var minC = diffValue / minute;

		switch (true) {
			case monthC >= 3:
				const formattedTime = new Date(time).toLocaleString();
				el.innerHTML = formattedTime;
				break;
			case monthC >= 1:
				el.innerHTML = "" + parseInt(monthC) + "月前";
				break;
			case weekC >= 1:
				el.innerHTML += "" + parseInt(weekC) + "周前";
				break;
			case dayC >= 1:
				el.innerHTML += "" + parseInt(dayC) + "天前";
				break;
			case hourC >= 1:
				el.innerHTML += "" + parseInt(hourC) + "小时前";
				break;
			case minC >= 1:
				el.innerHTML += "" + parseInt(minC) + "分钟前";
				break;

			default:
				el.innerHTML = "刚刚";
		}
	},
};
