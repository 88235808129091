import request from "@/utils/request";

export function login(data) {
	return request({
		url: "/login",
		method: "post",
		data: data,
	});
}

export function getInfo(obj) {
	return request({
		url: "/getUserInfo",
		method: "post",
		data: obj,
	});
}

//获取用户权限
export function getAuthMenu() {
	return request({
		url: "/admin/getPermissions",
		method: "post",
		data: { platform: 1 },
	});
}
// 上传图片

export function uploadImage(file) {
	return request({
		url: "/upload",
		method: "post",
		data: file,
	});
}
//   获取用户列表
export function getUsersList(obj) {
	return request({
		url: "/admin/users/lists",
		method: "post",
		data: obj,
	});
}
//   用户详情

export function getUsersdetails(obj) {
	return request({
		url: "/admin/users/details",
		method: "get",
		params: obj,
	});
}
//   新增用户

export function createusers(obj) {
	return request({
		url: "/admin/users/create",
		method: "post",
		data: obj,
	});
}

//   编辑用户

export function updateusers(obj) {
	return request({
		url: "/admin/users/update",
		method: "post",
		data: obj,
	});
}
//   删除用户
export function destroyusers(obj) {
	return request({
		url: "/admin/users/destroy",
		method: "post",
		data: obj,
	});
}
//   修改个人信息
export function editUserInfo(obj) {
	return request({
		url: "/editUserInfo",
		method: "post",
		data: obj,
	});
}
//   验证验证码

export function verifySms(obj) {
	return request({
		url: "/verifySms",
		method: "post",
		data: obj,
	});
}
//   设置密码

export function setPassword(obj) {
	return request({
		url: "/setPassword",
		method: "post",
		data: obj,
	});
}
//   我的收藏
export function myCollection(obj) {
	return request({
		url: "/myCollection",
		method: "post",
		data: obj,
	});
}
//   我的关注
export function myFollow(obj) {
	return request({
		url: "/myFollow",
		method: "post",
		data: obj,
	});
}
//   关注作者
export function followAuthor(obj) {
	return request({
		url: "/followAuthor",
		method: "post",
		data: obj,
	});
}
//   消息中心
export function getMessageDetails(obj) {
	return request({
		url: "/getMessageDetails",
		method: "post",
		data: obj,
	});
}
//   认证信息
export function getAuthInfo(obj) {
	return request({
		url: "/getAuthInfo",
		method: "post",
		data: obj,
	});
}
//   提交举报

export function submitReport(obj) {
	return request({
		url: "/submitReport",
		method: "post",
		data: obj,
	});
}
//   提交反馈
export function submitFeedback(obj) {
	return request({
		url: "/submitFeedback",
		method: "post",
		data: obj,
	});
}
//   利好利空
export function likeInformation(obj) {
	return request({
		url: "/likeInformation",
		method: "post",
		data: obj,
	});
}
//   退出登录
export function logout(obj) {
	return request({
		url: "/logOut",
		method: "post",
		data: obj,
	});
}

//获取微信分享授权
export function getShareSignaTure(data) {
	return request({
		url: "/getShareSignaTure",
		dataType: "json",
		method: "post",
		data,
	});
}
